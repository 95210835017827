import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Table } from 'react-bootstrap';
import { uniqBy } from 'lodash';

import { displayPrice } from '~/helpers/numbers';
import {
  subtitlingOrderOptionType,
  translationProfileType,
} from '~/components/app/order_form/propTypes';
import TranslationProfileDropdown from '~/components/app/order_form/serviceOptions/translation/TranslationProfileDropdown';
import TranslationProfileInfoPopover from '~/components/app/order_form/serviceOptions/translation/TranslationProfileInfoPopover';

const TurnaroundLevelDropdown = ({
  availableTurnaroundLevelsForLanguage,
  selectedTurnaroundLevel,
  swapToTurnaroundLevel,
  targetLanguage,
}) => {
  return (
    <Form.Control
      as="select"
      onChange={(e) => swapToTurnaroundLevel(targetLanguage, e.target.value)}
      value={selectedTurnaroundLevel}
      size="sm"
    >
      {availableTurnaroundLevelsForLanguage.map((turnaroundLevel) => {
        return (
          <option key={turnaroundLevel.id} value={turnaroundLevel.id}>
            {turnaroundLevel.name}
          </option>
        );
      })}
    </Form.Control>
  );
};

const SubtitlingOrderOptionsTable = ({
  subtitlingOrderData,
  subtitlingOrderOptions,
  translationProfiles,
  updateOrderOptions,
}) => {
  const selectedOrderOptions = subtitlingOrderData?.selectedSubtitlingOptions || [];

  // Memoize the available turnaround levels for each target language
  const availableTurnaroundLevelsByLanguage = useMemo(() => {
    return subtitlingOrderOptions.reduce((acc, option) => {
      const { id } = option.targetLanguage;
      if (!acc[id]) {
        acc[id] = [];
      }
      acc[id].push(option.turnaroundLevel);
      acc[id] = uniqBy(acc[id], 'id');
      return acc;
    }, {});
  }, [subtitlingOrderOptions]);

  const swapToTurnaroundLevel = (targetLanguage, turnaroundLevelId) => {
    const { selectedSubtitlingOptions } = subtitlingOrderData;
    const newSelectedSubtitlingOptions = [...selectedSubtitlingOptions];

    const indexOfOrderOptionToReplace = newSelectedSubtitlingOptions.findIndex(
      (option) => option.targetLanguage.id === targetLanguage.id
    );

    if (indexOfOrderOptionToReplace === -1) return;

    const orderOptionOfCorrectType = subtitlingOrderOptions.find(
      (option) =>
        option.targetLanguage.id === targetLanguage.id &&
        option.turnaroundLevel.id === parseInt(turnaroundLevelId, 10)
    );

    if (!orderOptionOfCorrectType) return;

    const orderOptionWithTranslationProfile = {
      ...orderOptionOfCorrectType,
      translationProfileID:
        newSelectedSubtitlingOptions[indexOfOrderOptionToReplace].translationProfileID,
    };

    newSelectedSubtitlingOptions[indexOfOrderOptionToReplace] = orderOptionWithTranslationProfile;

    updateOrderOptions('Subtitling', {
      ...subtitlingOrderData,
      selectedSubtitlingOptions: newSelectedSubtitlingOptions,
    });
  };

  const setTranslationProfileForLanguage = (targetLanguage, translationProfileID) => {
    const { selectedSubtitlingOptions } = subtitlingOrderData;
    const newSelectedSubtitlingOptions = [...selectedSubtitlingOptions];

    const indexOfOrderOptionToReplace = newSelectedSubtitlingOptions.findIndex(
      (option) => option.targetLanguage.id === targetLanguage.id
    );

    if (indexOfOrderOptionToReplace === -1) return;

    newSelectedSubtitlingOptions[indexOfOrderOptionToReplace]['translationProfileID'] =
      translationProfileID;

    updateOrderOptions('Subtitling', {
      ...subtitlingOrderData,
      selectedSubtitlingOptions: newSelectedSubtitlingOptions,
    });
  };

  if (selectedOrderOptions.length === 0) {
    return null;
  } else {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Language</th>
            <th>Turnaround</th>
            <th>
              Translation Profile <TranslationProfileInfoPopover />
            </th>
            <th>Estimated Cost</th>
          </tr>
        </thead>
        <tbody>
          {selectedOrderOptions.map((orderOption) => {
            const availableTurnaroundLevels =
              availableTurnaroundLevelsByLanguage[orderOption.targetLanguage.id] || [];
            return (
              <tr key={orderOption.targetLanguage.id}>
                <td className="align-middle">{orderOption.targetLanguage.fullName}</td>
                <td className="align-middle">
                  <TurnaroundLevelDropdown
                    availableTurnaroundLevelsForLanguage={availableTurnaroundLevels}
                    selectedTurnaroundLevel={orderOption.turnaroundLevel.id}
                    swapToTurnaroundLevel={swapToTurnaroundLevel}
                    targetLanguage={orderOption.targetLanguage}
                  />
                </td>
                <td>
                  <TranslationProfileDropdown
                    selectedTranslationProfile={orderOption.translationProfileID}
                    setSelectedTranslationProfile={(e, targetLanguage) =>
                      setTranslationProfileForLanguage(targetLanguage, e.target.value)
                    }
                    targetLanguage={orderOption.targetLanguage}
                    translationProfiles={translationProfiles}
                  />
                </td>
                <td className="align-middle">
                  {displayPrice(orderOption.price.amount, orderOption.price.unit, false)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
};

SubtitlingOrderOptionsTable.propTypes = {
  subtitlingOrderData: PropTypes.object,
  subtitlingOrderOptions: subtitlingOrderOptionType,
  translationProfiles: PropTypes.arrayOf(translationProfileType),
  updateOrderOptions: PropTypes.func,
};

TurnaroundLevelDropdown.propTypes = {
  availableTurnaroundLevelsForLanguage: PropTypes.arrayOf(PropTypes.object),
  selectedTurnaroundLevel: PropTypes.string,
  swapToTurnaroundLevel: PropTypes.func,
  targetLanguage: PropTypes.string,
};

export default SubtitlingOrderOptionsTable;
