import React, { useContext, Context, useMemo } from 'react';
import PropTypes from 'prop-types';

import ErrorOverlay from '~/components/app/common/error_boundaries/ErrorOverlay';
import OrderOptionsContainer from '~/components/app/order_form/serviceOptions/OrderOptionsContainer';
import ObnoxiousYellowBox from '~/components/app/order_form/units/ObnoxiousYellowBox';
import { ProjectContext } from '../OrderForm';
import AudioDescriptionMixSection from '../AudioDescriptionMixSection';
import { useAudioDescriptionOptions } from '~/components/app/order_form/serviceOptions/audioDescription/useAudioDescriptionOptions';
import SolutionTypeSection from '~/components/app/order_form/serviceOptions/audioDescription/SolutionTypeSection';
import ServiceLevelSection from '~/components/app/order_form/serviceOptions/audioDescription/ServiceLevelSection';
import TurnaroundTimeSection from '~/components/app/order_form/serviceOptions/audioDescription/TurnaroundTimeSection';
import { getDisplayableServiceLevels } from '~/components/app/order_form/serviceOptions/audioDescription/ServiceLevelSection';
import { getDisplayableTurnaroundLevels } from '~/components/app/order_form/serviceOptions/audioDescription/TurnaroundTimeSection';
export interface TurnaroundLevel {
  id: string;
  name: string;
  displayName: string;
  price?: number;
  surcharge: number;
  deliveryDate: string;
  serviceType: string;
}

export interface ServiceLevel {
  name: string;
  price: number;
  displayPrice: string;
  description: string;
  deliveryDate?: string;
  voiceArtistPrice?: number;
  voiceArtistDisplayPrice?: string;
  aiOnlyPrice?: number;
  aiOnlyDisplayPrice?: string;
}

export interface SolutionType {
  name: string;
  price: {
    amount: number;
    unit: string;
  };
  displayPrice: string;
  description: string;
}

interface OutputAssetOption {
  validForExtended: boolean;
  [key: string]: any;
}

export interface OrderOptions {
  speakerType?: Omit<SolutionType, 'description'>;
  serviceLevel?: Omit<ServiceLevel, 'description' | 'deliveryDate'>;
  turnaroundLevel?: Omit<TurnaroundLevel, 'serviceType' | 'surcharge'>;
  outputAssetOptions?: Record<string, OutputAssetOption>;
  [key: string]: any;
}

interface SelectedPrimaryService {
  serviceType: string;
  orderOptions?: {
    turnaroundLevel?: {
      id: string;
    };
  };
}

interface AudioDescriptionOrderOptionsProps {
  cheatSheetComponent: (serviceName: string, options?: any) => React.ReactNode;
  orderOptions?: OrderOptions;
  selectedLanguage?: string;
  selectedPrimaryService?: SelectedPrimaryService;
  setVoiceArtistAdWarningSelected: (selected: boolean) => void;
  updateOrderOptions: (service: string, options: OrderOptions) => void;
  voiceArtistAdWarningSelected?: boolean;
}

interface AudioDescriptionOptionsResult {
  availableServiceLevels: ServiceLevel[];
  availableTurnaroundLevels: TurnaroundLevel[];
  availableSolutionTypes: SolutionType[];
  errors: string[];
  isLoading: boolean;
}

interface AccountType {
  studio: boolean;
  [key: string]: any;
}

interface ProjectContextType {
  accountType: AccountType;
}

function AudioDescriptionOrderOptions({
  cheatSheetComponent,
  orderOptions = {},
  selectedLanguage,
  selectedPrimaryService,
  setVoiceArtistAdWarningSelected,
  updateOrderOptions,
  voiceArtistAdWarningSelected,
}: AudioDescriptionOrderOptionsProps) {
  const { accountType } = useContext(ProjectContext as Context<ProjectContextType>);
  const voiceArtistServiceType = 'AudioDescriptions::VoiceOvers::VoiceOverAudioDescriptionService';
  const isVoiceArtistAD = orderOptions?.speakerType?.name === 'Voice Artist';
  const isAiOnly = orderOptions?.speakerType?.name === 'AI Only';

  const {
    availableServiceLevels,
    availableTurnaroundLevels,
    availableSolutionTypes,
    errors,
    isLoading,
  }: AudioDescriptionOptionsResult = useAudioDescriptionOptions({
    selectedLanguage,
    selectedPrimaryService,
    orderOptions,
    updateOrderOptions,
  });

  const displayableServiceLevels = useMemo(() => {
    return getDisplayableServiceLevels(availableServiceLevels, isAiOnly);
  }, [availableServiceLevels, isAiOnly]);

  const displayableTurnaroundLevels = useMemo(() => {
    return getDisplayableTurnaroundLevels(
      availableTurnaroundLevels,
      isVoiceArtistAD,
      isAiOnly,
      voiceArtistServiceType
    );
  }, [isVoiceArtistAD, voiceArtistServiceType, availableTurnaroundLevels, isAiOnly]);

  function setTurnaroundLevel(turnaroundLevelId: string) {
    const turnaroundLevel = availableTurnaroundLevels.find((tl) => tl.id === turnaroundLevelId);
    if (!turnaroundLevel) return;

    updateOrderOptions('AudioDescription', {
      ...orderOptions,
      turnaroundLevel: {
        id: turnaroundLevel.id,
        name: turnaroundLevel.name,
        displayName: turnaroundLevel.displayName,
        price: turnaroundLevel.surcharge,
        deliveryDate: turnaroundLevel.deliveryDate,
      },
    });
  }

  function setServiceLevel(serviceLevel: string) {
    const selectedServiceLevel = availableServiceLevels.find((sl) => sl.name === serviceLevel);
    if (!selectedServiceLevel) return;

    // Remove mixing options that are invalid for extended if serviceLevel is not standard
    let outputAssetOptions = { ...orderOptions?.outputAssetOptions };
    if (outputAssetOptions) {
      outputAssetOptions = Object.fromEntries(
        Object.entries(outputAssetOptions).filter(([, value]) => {
          return value?.validForExtended || serviceLevel === 'Standard';
        })
      );
    }

    updateOrderOptions('AudioDescription', {
      ...orderOptions,
      outputAssetOptions,
      serviceLevel: {
        name: serviceLevel,
        price: selectedServiceLevel.price,
        displayPrice: selectedServiceLevel.displayPrice,
        voiceArtistPrice: selectedServiceLevel?.voiceArtistPrice,
        voiceArtistDisplayPrice: selectedServiceLevel?.voiceArtistDisplayPrice,
        aiOnlyPrice: selectedServiceLevel?.aiOnlyPrice,
        aiOnlyDisplayPrice: selectedServiceLevel?.aiOnlyDisplayPrice,
      },
    });
  }

  // TODO: use useSolutionTypeChange hook created for order_more
  function setSolutionType(solutionType: string) {
    const selectedSolutionType = availableSolutionTypes.find((st) => st.name === solutionType);
    if (!selectedSolutionType) return;

    const isVoiceArtist = solutionType === 'Voice Artist';
    const isAiOnly = solutionType === 'AI Only';
    const serviceType = isVoiceArtist ? voiceArtistServiceType : 'AudioDescriptionService';

    const standardTurnaround = availableTurnaroundLevels.find(
      (tl) => tl.name.includes('Standard') && tl.serviceType === serviceType
    );
    if (!standardTurnaround) return;

    const updatedOptions: OrderOptions = {
      ...orderOptions,
      speakerType: {
        name: solutionType,
        price: selectedSolutionType.price,
        displayPrice: selectedSolutionType.displayPrice,
      },
      turnaroundLevel: {
        id: standardTurnaround.id,
        name: standardTurnaround.name,
        displayName: standardTurnaround.displayName,
        price: standardTurnaround.surcharge,
        deliveryDate: standardTurnaround.deliveryDate,
      },
    };

    const standardServiceLevel = availableServiceLevels.find((sl) => sl.name === 'Standard');
    if (standardServiceLevel) {
      const serviceLevelBase = {
        name: standardServiceLevel.name,
        price: standardServiceLevel.price,
        displayPrice: standardServiceLevel.displayPrice,
      };

      if (isVoiceArtist) {
        updatedOptions.serviceLevel = {
          ...serviceLevelBase,
          voiceArtistPrice: standardServiceLevel.voiceArtistPrice,
          voiceArtistDisplayPrice: standardServiceLevel.voiceArtistDisplayPrice,
        };
      } else if (isAiOnly) {
        updatedOptions.serviceLevel = {
          ...serviceLevelBase,
          aiOnlyPrice: standardServiceLevel.aiOnlyPrice,
          aiOnlyDisplayPrice: standardServiceLevel.aiOnlyDisplayPrice,
        };
      }
    }

    updateOrderOptions('AudioDescription', updatedOptions);
  }

  const VoiceArtistWarning = () => {
    if (!isVoiceArtistAD) return null;

    return (
      <ObnoxiousYellowBox
        checked={voiceArtistAdWarningSelected ?? false}
        title="Voice Artist Audio Description cannot be Refunded"
        toggleChecked={setVoiceArtistAdWarningSelected}
      >
        By checking this box, you indicate that you will not have the opportunity to review your
        transcripts before they are submitted for audio description. Voice artist audio description
        will begin as soon as the primary service is completed.
      </ObnoxiousYellowBox>
    );
  };

  const MixSection = () => {
    if (!(isVoiceArtistAD && accountType.studio)) return null;

    return (
      <span>
        <AudioDescriptionMixSection
          orderOptions={orderOptions}
          updateOrderOptions={updateOrderOptions}
        />
      </span>
    );
  };

  if (errors.length > 0) {
    return (
      <ErrorOverlay
        messages={['Please refresh the page and contact support if the problem persists.']}
      />
    );
  }

  if (isLoading) {
    return <div>Loading audio description options...</div>;
  }

  return (
    <OrderOptionsContainer title="Audio Description" componentName="AudioDescriptionOrderOptions">
      <SolutionTypeSection
        availableSolutionTypes={availableSolutionTypes}
        orderOptions={orderOptions}
        setSolutionType={setSolutionType}
      />
      <ServiceLevelSection
        displayableServiceLevels={displayableServiceLevels}
        orderOptions={orderOptions}
        setServiceLevel={setServiceLevel}
      />
      <TurnaroundTimeSection
        displayableTurnaroundLevels={displayableTurnaroundLevels}
        orderOptions={orderOptions}
        setTurnaroundLevel={setTurnaroundLevel}
      />

      {cheatSheetComponent('audioDescriptionService', {
        showVoiceArtistAudioDescriptionNote: isVoiceArtistAD,
      })}

      <MixSection />
      <VoiceArtistWarning />
    </OrderOptionsContainer>
  );
}

AudioDescriptionOrderOptions.propTypes = {
  cheatSheetComponent: PropTypes.func,
  orderOptions: PropTypes.object,
  selectedLanguage: PropTypes.string,
  selectedPrimaryService: PropTypes.shape({
    serviceType: PropTypes.string,
    orderOptions: PropTypes.shape({
      turnaroundLevel: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
  setVoiceArtistAdWarningSelected: PropTypes.func,
  updateOrderOptions: PropTypes.func,
  voiceArtistAdWarningSelected: PropTypes.bool,
};

export default AudioDescriptionOrderOptions;
