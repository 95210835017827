const ALIGNMENT = 'Alignment';
const ASR_SERVICE = 'ASR Service';
const AUDIO_DESCRIPTION = 'Audio Description';
const TRANSCRIPTION_AND_CAPTIONING = 'Transcription and Captioning';
const AI_DUBBING_SERVICE = 'AI Dubbing';

const STATUS_COMPLETE = 'complete';

const service_type_names = [
  AI_DUBBING_SERVICE,
  ALIGNMENT,
  'Amara Translation',
  ASR_SERVICE,
  'Audio Description Import',
  'Audio Description Revision',
  AUDIO_DESCRIPTION,
  'Audio Mixing',
  'Automated Caption Placement',
  'Broadcast Scripts',
  'Broadcast Scripts Revision',
  'Caption Encoding',
  'Caption Import',
  'Caption Reformat',
  'Caption Revision',
  'Descriptive Transcript',
  'Dubbing',
  'Dubbing Revision',
  'Live Auto Captioning',
  'Live Captioning',
  'Manual Caption Placement',
  TRANSCRIPTION_AND_CAPTIONING,
  'Other Language Transcription',
  'Transcript Review',
  'Translation',
  'Translation Revision',
  'Video Clip Captioning',
  'Video Clip Captioning Parent',
  'Voice Artist Audio Description',
  'Voice Artist Audio Description Reformat',
];

const SERVICE_TYPES = Object.fromEntries(service_type_names.map((name, ii) => [name, ii]));

const MultiServiceTypes = [
  AI_DUBBING_SERVICE,
  'Amara Translation',
  AUDIO_DESCRIPTION,
  'Audio Description Narration',
  'Audio Description Revision',
  'Voice Artist Audio Description',
  'Voice Artist Audio Description Reformat',
  'Audio Description Import',
  'Audio Mixing',
  'Broadcast Scripts',
  'Broadcast Scripts Revision',
  'Caption Encoding',
  'Caption Import',
  'Caption Revision',
  'Dubbing',
  'Dubbing Revision',
  'Subtitling',
  'Translation',
  'Translation Revision',
];

const PRIMARY_SERVICES = [ALIGNMENT, ASR_SERVICE, TRANSCRIPTION_AND_CAPTIONING];

function isMultiServiceType(serviceType) {
  return MultiServiceTypes.includes(serviceType);
}

const isServiceCancellable = (serviceName, services, serviceGroups) => {
  if (serviceName == AUDIO_DESCRIPTION) {
    return isAudioDescriptionCancellable(services, serviceGroups);
  }

  // multi service types (except for audio descriptions!) cannot be cancelled
  return !isMultiServiceType(serviceName) && !!services[0]?.cancellable;
};

// can only cancel an audio description if a primary service is < complete
const isAudioDescriptionCancellable = (audioDescription, services) => {
  const primaryService = getPrimaryService(services);
  const primaryServiceStatus = primaryService[0]?.status;
  return (
    !!primaryServiceStatus &&
    primaryServiceStatus !== STATUS_COMPLETE &&
    !!audioDescription[0]?.cancellable
  );
};

// we are assuming that there will only be one primary service
const getPrimaryService = (services) => {
  return services.filter(({ name }) => PRIMARY_SERVICES.includes(name));
};

export { SERVICE_TYPES, isMultiServiceType, isServiceCancellable };
