import React from 'react';
import { useOrderOptions } from './OrderOptionsContext';

const TipPanelContent = () => {
  const { orderOptions } = useOrderOptions();

  return (
    <div>
      <h3>Selected Order Options</h3>
      <p>Select your options in the form to customize your order.</p>

      <ul>
        {orderOptions?.speakerType && (
          <li>
            <strong>Solution:</strong> {orderOptions.speakerType.name || 'Not selected'}
          </li>
        )}
        {orderOptions?.serviceLevel && (
          <li>
            <strong>Type:</strong> {orderOptions.serviceLevel.name || 'Not selected'}
          </li>
        )}
        {orderOptions?.turnaroundLevel && (
          <li>
            <strong>Turnaround:</strong>{' '}
            {orderOptions.turnaroundLevel.displayName || 'Not selected'}
          </li>
        )}
      </ul>
    </div>
  );
};

export default TipPanelContent;
