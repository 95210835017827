import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';

import TipPanel from '~/components/app/common/TipPanel';

import { mediaFileEndpoint } from '~/helpers/app/paths';

/*
 * onSubmit must return a promise.
 * You can do this by either returning a fetch().then() chain
 * or by making the onSubmit function async
 */
const OrderMore = ({
  additionalErrors = [],
  mainContent,
  onSubmit,
  orderType,
  submissionDisabled,
  summaryContent,
  tipPanelContent,
}) => {
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const submit = () => {
    setErrors([]);
    setSubmitting(true);
    setSuccess(false);
    onSubmit({ setErrors, setSuccess }).finally(() => {
      setSubmitting(false);
    });
  };

  const allErrors = [...additionalErrors, ...errors];
  return (
    <div className={css(styles.orderMoreWrapper)}>
      <Container fluid>
        <Row className="p-2">
          <Col xs={8}>
            {allErrors.length > 0 && <Alert variant="danger">{allErrors[0]}</Alert>}
            {!success && (
              <>
                {mainContent}
                <Button
                  type="submit"
                  onClick={submit}
                  variant="primary"
                  disabled={submitting || submissionDisabled}
                  style={{ marginTop: '16px' }}
                >
                  {submitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  Order {orderType}
                </Button>
              </>
            )}
            {success && (
              <>
                <Alert variant="success" className={css(styles.success)}>
                  <i className={'fa fa-check fa-3x'} />
                  <h2>Order Success</h2>
                  Your <b>{orderType}</b> order has been submitted successfully.
                </Alert>
                {summaryContent}
                <div className={css(styles.returnHome)}>
                  <a
                    href={mediaFileEndpoint}
                    style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                  >
                    <i className={'fa fa-home'} />
                    <span style={{ marginLeft: '0.5rem' }}>Return to My Files</span>
                  </a>
                </div>
              </>
            )}
          </Col>
          <Col xs={4}>
            <TipPanel header={orderType}>{tipPanelContent}</TipPanel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const styles = StyleSheet.create({
  orderMoreWrapper: {
    margin: 'auto',
    width: '90%',
  },
  returnHome: {
    padding: '32px 0 0 0',
    textAlign: 'center',
  },
  success: {
    padding: '32px 0',
    textAlign: 'center',
  },
});

OrderMore.propTypes = {
  additionalErrors: PropTypes.arrayOf(PropTypes.string),
  mainContent: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  submissionDisabled: PropTypes.bool.isRequired,
  summaryContent: PropTypes.node,
  tipPanelContent: PropTypes.node,
};

export default OrderMore;
