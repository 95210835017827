import { useState, useEffect } from 'react';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { additionalServicesQuery } from '~/components/app/order_form/data/queries';

export function useAudioDescriptionOptions({
  selectedLanguage,
  selectedPrimaryService,
  orderOptions = {},
  updateOrderOptions,
}) {
  const [availableServiceLevels, setAvailableServiceLevels] = useState([]);
  const [availableTurnaroundLevels, setAvailableTurnaroundLevels] = useState([]);
  const [availableSolutionTypes, setAvailableSolutionTypes] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const findOptionByName = (options, name) => {
    return options?.find((option) => option.name === name);
  };

  const setDefaultOptions = (options) => {
    const defaultTurnaround = findOptionByName(options.turnaroundLevels, 'Standard AD');
    const defaultServiceLevel = findOptionByName(options.serviceLevels, 'Standard');

    // This is only necessary while we are in the process of rebranding
    let defaultSpeakerType = findOptionByName(options.speakerTypes, 'Synthesized');
    if (!defaultSpeakerType) {
      defaultSpeakerType = findOptionByName(options.speakerTypes, 'Professional AI');
    }

    updateOrderOptions('AudioDescription', {
      ...orderOptions,
      turnaroundLevel: defaultTurnaround && {
        id: defaultTurnaround.id,
        name: 'Standard AD',
        displayName: defaultTurnaround.displayName,
        price: defaultTurnaround.surcharge,
        deliveryDate: defaultTurnaround.deliveryDate,
      },
      serviceLevel: defaultServiceLevel && {
        name: 'Standard',
        price: defaultServiceLevel.price,
        displayPrice: defaultServiceLevel.displayPrice,
        voiceArtistPrice: defaultServiceLevel?.voiceArtistPrice,
        voiceArtistDisplayPrice: defaultServiceLevel?.voiceArtistDisplayPrice,
      },
      ...(defaultSpeakerType && {
        speakerType: {
          name: defaultSpeakerType.name,
          price: defaultSpeakerType.price,
          displayPrice: defaultSpeakerType.displayPrice,
        },
      }),
    });
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchAudioDescriptionOptions = async () => {
      try {
        const res = await threeplayApi.request(additionalServicesQuery, {
          languageType: selectedLanguage,
          primaryServiceType: selectedPrimaryService.serviceType,
          primaryServiceTurnaroundLevel:
            selectedPrimaryService.orderOptions.turnaroundLevel &&
            parseInt(selectedPrimaryService.orderOptions.turnaroundLevel.id),
          selectedAdditionalServiceType: 'AudioDescription',
        });

        if (!isMounted) return;

        const data = res.data || {};
        const audioDescriptionService = data.project?.orderableServices[0].additionalServices.find(
          (service) => service.serviceType === 'AudioDescription'
        );
        const options = audioDescriptionService?.orderOptions;

        if (res.errors) {
          setErrors(res.errors.map((error) => error.message));
        } else if (options?.turnaroundLevels || options?.serviceLevels) {
          setAvailableServiceLevels(options.serviceLevels || []);
          setAvailableTurnaroundLevels(options.turnaroundLevels || []);
          setAvailableSolutionTypes(options.speakerTypes || []);

          if (!Object.keys(orderOptions).length) {
            setDefaultOptions(options);
          }
        }
      } catch (error) {
        if (!isMounted) return;
        setErrors([error.message || 'An unexpected error occurred']);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchAudioDescriptionOptions();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    availableServiceLevels,
    availableTurnaroundLevels,
    availableSolutionTypes,
    errors,
    isLoading,
  };
}
