import AudioAssetIcon from '~/components/app/order_form/images/service_icons/ad_mix_icons/audio.svg';
import AudioEmbedIcon from '~/components/app/order_form/images/service_icons/ad_mix_icons/ad_embedded.svg';
import VoiceoverIcon from '~/components/app/order_form/images/service_icons/ad_mix_icons/voiceover.svg';
import MonoIcon from '~/components/app/order_form/images/service_icons/ad_mix_icons/mono.svg';
import StereoIcon from '~/components/app/order_form/images/service_icons/ad_mix_icons/stereo.svg';
import FiveOneIcon from '~/components/app/order_form/images/service_icons/ad_mix_icons/fiveOne.svg';

// Linked account constants
export const SECTIONED_LAYOUT = 'sectioned';
export const CONTINUOUS_LAYOUT = 'continuous';
export const INTEGRATION_COUNT_THRESHOLD = 6;
export const BETA_PLATFORMS = ['Canvas'];

//Account Plan
export const MANAGE_PLAN_WIZARD_STEP_INDEX = {
  0: 'choosePlan',
  1: 'payment',
  2: 'confirmation',
};

export const MANAGE_PLAN_WIZARD_STEPS = ['Choose Plan', 'Payment', 'Confirmation'];

// Order Form
export const STEP_INDEX_ALL = {
  0: 'primaryServices',
  1: 'secondaryServices',
  2: 'upload',
  3: 'finalize',
};

export const STEP_INDEX_TRANSCRIPTION_ONLY = {
  0: 'primaryServices',
  1: 'upload',
  2: 'finalize',
};

export const ORDER_FORM_STEPS = [
  'Choose Main Service',
  'Add Optional Services',
  'Add or Upload Files',
  'Finalize Order',
];

export const ORDER_FORM_TRANSCRIPTION_ONLY_STEPS = [
  'Select Transcript Options',
  'Add or Upload Files',
  'Finalize Order',
];

export const DUBBING_TRANSLATION_TYPE_MAP = {
  machine: 'Machine',
  human: 'Human-in-the-Loop',
};

export const PRIMARY_SERVICES = ['Alignment', 'Asr', 'Transcription'];
export const PER_PAGE_COUNT = [5, 10, 25, 50, 100];
export const ADD = 'add';
export const REMOVE = 'remove';
export const CLEAR = 'clear';
export const ENGLISH_ID = 1;
export const ENGLISH_UK_ID = 3;
export const FRENCH_FRANCE_ID = 5;
export const GERMAN_ID = 7;
export const SPANISH_SPAIN_ID = 12;
export const SPANISH_ID = 13;
export const S3_VALIDATION_RETRIES = 20;
export const S3_VALIDATION_DELAY = 5000;

export const DUBBING_ONLY_ENGLISH_TOOLTIP =
  'Dubbing cannot be ordered because your files are in a language other than English.';
export const AI_DUBBING_ONLY_AFTER_TRANSCRIPTION_TOOLTIP =
  'Dubbing is only available as an additional service for Transcription services.';
export const AI_DUBBING_TRANSLATION_PROFILE_MISSING_TOOLTIP =
  'A translation profile is required to add dubbing to your order. This allows 3Play to create accurate dubbing for your content.';
export const MULTI_METHOD_TOOLTIP =
  'We are currently working on supporting multi-method uploads. We’ll let you know when this feature is completed!';
export const ONLY_ENGLISH_AD_ALLOWED_TOOLTIP =
  'Audio Description can not be ordered because your files are in a language other than English.';
export const ONLY_ENGLISH_AND_SPANISH_AD_ALLOWED_TOOLTIP =
  'Audio Description can not be ordered because your files are in a language other than English or Spanish.';
export const TRANSLATION_NOT_ALLOWED_TOOLTIP =
  'Translation can not be ordered because your files are in a language other than what is currently supported for translations.';
export const TRANSLATION_PROFILE_MISSING_TOOLTIP =
  'A translation profile is required to add translation to your order. This will help 3Play create the most accurate translations for your content.';
export const DOWNGRADED_LINKED_ACCOUNT_TOOLTIP =
  'This linked account has been disabled. Please upgrade to Pro to order files through this linked account.';
export const DOWNGRADED_LINKED_ACCOUNT_EDIT_TOOLTIP =
  'Please upgrade to Pro to edit details for this Linked Account.';
export const SCRIPTING_SERVICE_REQUIREMENTS_TOOLTIP =
  'Broadcast Scripts are only available as an additional service for English Transcription services.';

export const VALID_AD_LANGUAGE_IDS = [ENGLISH_ID, SPANISH_ID, ENGLISH_UK_ID, SPANISH_SPAIN_ID];
export const ADDITIONAL_AD_JUMPSTART_LANGUAGE_IDS = [FRENCH_FRANCE_ID, GERMAN_ID];

export const SERVICES_REQUIRING_VIDEO_SOURCE = ['AudioDescription', 'CaptionPlacement'];
export const SERVICES_SUPPORTING_WORDLIST = ['Transcription', 'Asr'];

export const GENGO_VENDOR_ID = 2;
export const MACHINE_TRANSLATOR_VENDOR_ID = 4;
export const TRANSPERFECT_VENDOR_ID = 5;
export const CHOOSE_FOR_ME_VENDOR_ID = 6;
export const VENDORS_WITH_PER_WORD_PRICING = [GENGO_VENDOR_ID, MACHINE_TRANSLATOR_VENDOR_ID];

export const AUDIO_MIX_CODE_ICON_MAP = {
  audio: AudioAssetIcon,
  embedded: AudioEmbedIcon,
  voiceover: VoiceoverIcon,
  mono: MonoIcon,
  stereo: StereoIcon,
  fiveOne: FiveOneIcon,
};

// Cheatsheets
export const MAX_CHEATSHEET_CHARS = 500;

// Keycode
export const ENTER_KEY_CODE = 13;
export const ESCAPE_KEY_CODE = 27;
export const SPACE_KEY_CODE = 32;
export const TAB_KEY_CODE = 9;
//Manage Plan Wizard Indexes
export const CHOOSE_PLAN_START_TAB_INDEX = 10;
export const MANAGE_PLAN_PAYMENT_START_TAB_INDEX = 10;
export const MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX = 50;
export const MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX = 75;
export const MANAGE_PLAN_CONFIRMATION_START_TAB_INDEX = 10;
export const MANAGE_PLAN_CONTACT_US_TAB_INDEX = 100;
export const MANAGE_PLAN_MODAL_CLOSE_BUTTON_TAB_INDEX = 1;
export const MANAGE_PLAN_STEP_TAB_INDEX_OFFSET = MANAGE_PLAN_MODAL_CLOSE_BUTTON_TAB_INDEX + 1;

//Tab Indexes
//// Step 1
export const SERVICE_START_INDEX = 10;
export const LANGUAGE_SELECTOR_INDEX = 100;
export const TURNAROUND_LEVEL_START_INDEX = 200;
export const SERVICE_LEVEL_START_INDEX = 300;
//// Step 2
export const ADDITIONAL_SERVICES_START_INDEX = 10;
export const AD_SPEAKERS_START_INDEX = 200;
export const AD_OPTIONS_START_INDEX = 225;
export const AD_TURNAROUND_START_INDEX = 250;
export const AD_AUDIO_MIX_START_INDEX = 275;
export const CAPTION_PLACEMENT_OPTIONS_START_INDEX = 300;
export const SCRIPTING_OPTIONS_START_INDEX = 600;
export const DUBBING_START_INDEX = 700;
//// Step 3
export const ADD_FILES_INDEX = 15;
export const FTP_UPLOAD_INDEX = 50;
export const FILE_UPLOAD_TABLE_INDEX = 100;
export const BATCH_NEW_FOLDER_INDEX = 200;
export const BATCH_NEW_FOLDER_TEXT_INDEX = 201;
export const BATCH_EXISTING_FOLDER_INDEX = 202;
export const BATCH_EXISTING_FOLDER_SELECT_INDEX = 203;
export const TEMPLATE_NEW_INDEX = 300;
export const TEMPLATE_SUBMIT_BUTTON_INDEX = 301;
//// Finalize Step
export const FINISH_BUTTON_INDEX = 1001;
export const FAQ_ONE_INDEX = 1002;
export const FAQ_TWO_INDEX = 1003;
export const FAQ_THREE_INDEX = 1004;
//// Step agnostic
export const CHEATSHEET_CHECK_INDEX = 400;
export const CHEATSHEET_TEXT_INDEX = 401;
export const CHEATSHEET_FILE_BUTTON_INDEX = 402;
export const BACK_BUTTON_INDEX = 999;
export const NEXT_BUTTON_INDEX = 1000;
