import React from 'react';
import { Card } from 'react-bootstrap';
import { css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';
import SolutionTypeCard from '~/components/app/order_form/serviceOptions/audioDescription/SolutionTypeCard';
import { AD_SPEAKERS_START_INDEX } from '~/helpers/constants';
import { OrderOptions } from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';
export interface SolutionType {
  name: string;
  price: {
    amount: number;
    unit: string;
  };
  displayPrice: string;
  description: string;
}

interface SolutionTypeSectionProps {
  availableSolutionTypes: SolutionType[];
  orderOptions: OrderOptions;
  setSolutionType: (solutionType: string) => void;
}

const SolutionTypeSection: React.FC<SolutionTypeSectionProps> = ({
  availableSolutionTypes,
  orderOptions,
  setSolutionType,
}) => {
  if (!availableSolutionTypes?.length) return null;

  return (
    <>
      <Card.Title className={css(appStyles.title)}>Audio Description Solution</Card.Title>
      <div className="d-flex">
        {availableSolutionTypes?.map((type, index) => (
          <SolutionTypeCard
            solutionType={type}
            key={type.name}
            index={index + AD_SPEAKERS_START_INDEX}
            orderOptions={orderOptions}
            setSolutionType={setSolutionType}
            showBeta={type.name === 'AI Only'}
          />
        ))}
      </div>
    </>
  );
};

export default SolutionTypeSection;
