import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Card, ListGroup } from 'react-bootstrap';

import SurchargeNote from '~/components/app/order_form/SurchargeNote';
import {
  BroadcastScriptDetails,
  DubbingServiceDetails,
  OutputAssetDetails,
  SubtitlingServiceDetails,
  TranslationServiceDetails,
  serviceDetailStyles,
} from '~/components/app/order_form/serviceDetails';
import BetaTermsNotice from '~/components/app/order_form/BetaTermsNotice';
import NoRefundWarning from '~/components/app/order_form/units/NoRefundWarning';

import { ProjectContext } from './OrderForm';
import { selectedServicesType } from './propTypes';

import { displayPrice } from '~/helpers/numbers';
import { ENGLISH_ID, SPANISH_ID } from '~/helpers/constants';

function price(service, serviceLevel) {
  if (service.serviceType === 'Transcription' && service.orderOptions) {
    return displayPrice(
      service.orderOptions?.language?.price,
      service.orderOptions?.language?.unit,
      false
    );
  } else if (
    service.serviceType === 'AudioDescription' &&
    service.orderOptions?.speakerType?.name === 'AI Only'
  ) {
    return serviceLevel.aiOnlyDisplayPrice;
  } else if (
    service.serviceType === 'AudioDescription' &&
    service.orderOptions?.speakerType?.name === 'Voice Artist'
  ) {
    return serviceLevel.voiceArtistDisplayPrice;
  } else if (service.serviceType === 'BroadcastScript' && service.orderOptions) {
    if (service.orderOptions.formats && service.orderOptions.formats.length > 0) {
      const total = service.orderOptions.formats.reduce((carry, item) => {
        return (carry += item.price.amount);
      }, 0);
      return displayPrice(total, service.orderOptions.formats[0].price.unit, false);
    }
    // Until there are formats selected, there's no price to display
    return '';
  } else if (service.serviceType === 'DescriptiveTranscript' && service.orderOptions) {
    return (
      service.orderOptions.price &&
      displayPrice(service.orderOptions.price.amount, service.orderOptions.price.unit, false)
    );
  } else if (serviceLevel?.displayPrice) {
    return serviceLevel.displayPrice;
  } else {
    return service.price && displayPrice(service.price.amount, service.price.unit, false);
  }
}

function SelectedServicesPanel(props) {
  const { features, displayBlockPricingNote, accountType } = useContext(ProjectContext);

  return (
    <Card>
      <Card.Header as="h5" className={css(styles.selectedServicesHeader)}>
        Selected Services
      </Card.Header>
      <Card.Body>
        {props.selectedServices.length === 0 && <div>Select a service to begin…</div>}
        <ListGroup variant="flush">
          {props.selectedServices?.map((service) => {
            const turnaroundLevel = service.orderOptions?.turnaroundLevel;
            const language = service.orderOptions?.language;
            const speakerType = service.orderOptions?.speakerType;
            const serviceLevel = service.orderOptions?.serviceLevel;
            const isAIOnly = service.orderOptions?.speakerType?.name === 'AI Only';
            const dubbingOrderOptions = service.orderOptions?.selectedDubbingOptions;
            const subtitlingOrderOptions = service.orderOptions?.selectedSubtitlingOptions;
            const translationOrderOptions = service.orderOptions?.selectedOptions;
            const captionStyling = service.orderOptions?.captionStyling;
            const broadcastScriptFormats = service.orderOptions?.scriptFormats;
            const translationType = props.selectedTranslationType?.replace(/\s\(.*?\)/g, '');

            const outputAssetOptions = service.orderOptions?.outputAssetOptions;
            const embedding = service.orderOptions?.embedding;

            return (
              <ListGroup.Item key={service.serviceType} className={css(styles.listItem)}>
                <div className={css(serviceDetailStyles.serviceOptionHeader)}>
                  {service.serviceType === 'Translation' && translationType ? (
                    <b>
                      {service.displayName}: {translationType}
                    </b>
                  ) : (
                    <b>{service.displayName}</b>
                  )}
                  <div className={css(serviceDetailStyles.serviceOptionPrice)}>
                    {service.orderOptions?.language?.originalPrice && (
                      <>
                        <s>
                          <b>{service.orderOptions?.language?.originalPrice}</b>
                        </s>
                        <br />
                      </>
                    )}
                    <b>{price(service, serviceLevel)}</b>
                  </div>
                </div>
                <div className={css(styles.serviceDescriptions)}>
                  {speakerType && (
                    <div className={css(serviceDetailStyles.serviceOptionHeader)}>
                      <b>Solution:&nbsp;</b> {speakerType.name}
                    </div>
                  )}
                  {serviceLevel && (
                    <div className={css(serviceDetailStyles.serviceOptionHeader)}>
                      <b>Type:&nbsp;</b> {serviceLevel.name}
                    </div>
                  )}
                  {language && (
                    <div>
                      <b>Language:&nbsp;</b>
                      {language.fullName}
                    </div>
                  )}
                  {service.serviceType === 'AudioDescription' && props.hasProofToFinalFile && (
                    <p className={css(styles.muted)}>
                      *The Proof to Final surcharge will only be applied if the media file was
                      uploaded as Proof to Final.
                    </p>
                  )}
                  {captionStyling && (
                    <div className={css(serviceDetailStyles.serviceOptionHeader)}>
                      <b>Caption Styling:&nbsp;</b> {captionStyling.name}{' '}
                      {captionStyling.price && (
                        <div className={css(serviceDetailStyles.serviceOptionPrice)}>
                          {displayPrice(captionStyling.price.amount, captionStyling.price.unit)}
                        </div>
                      )}
                    </div>
                  )}
                  {broadcastScriptFormats?.length > 0 && (
                    <BroadcastScriptDetails selectedFormats={broadcastScriptFormats} />
                  )}
                  {turnaroundLevel && !isAIOnly && (
                    <div className={css(serviceDetailStyles.serviceOptionHeader)}>
                      <b>Turnaround:&nbsp;</b> {turnaroundLevel.displayName}{' '}
                      {turnaroundLevel.deliveryDate && '(by ' + turnaroundLevel.deliveryDate + ')'}
                      {turnaroundLevel.price && (
                        <div className={css(serviceDetailStyles.serviceOptionPrice)}>
                          {displayPrice(turnaroundLevel.price.amount, turnaroundLevel.price.unit)}
                        </div>
                      )}
                    </div>
                  )}
                  {dubbingOrderOptions?.length > 0 && (
                    <DubbingServiceDetails
                      dubbingOrderOptions={dubbingOrderOptions}
                      embedding={embedding}
                    />
                  )}
                  {subtitlingOrderOptions?.length > 0 && (
                    <SubtitlingServiceDetails subtitlingOrderOptions={subtitlingOrderOptions} />
                  )}
                  {translationOrderOptions?.length > 0 && (
                    <TranslationServiceDetails translationOrderOptions={translationOrderOptions} />
                  )}
                  {outputAssetOptions && (
                    <OutputAssetDetails outputAssetOptions={outputAssetOptions} />
                  )}
                </div>
                {props.showWarnings && (
                  <>
                    {!accountType.express &&
                      !!features.difficulty_surcharge_note &&
                      service.serviceType === 'Transcription' &&
                      (language.ids.includes(ENGLISH_ID) || language.ids.includes(SPANISH_ID)) && (
                        <SurchargeNote />
                      )}
                    {service.serviceType === 'Translation' && (
                      <NoRefundWarning serviceName="translation" />
                    )}
                    {service.serviceType === 'AudioDescription' &&
                      speakerType?.name === 'Voice Artist' && (
                        <NoRefundWarning serviceName="voice artist audio description" />
                      )}
                    {service.serviceType === 'BroadcastScript' && (
                      <NoRefundWarning serviceName="broadcast scripts" />
                    )}
                  </>
                )}
              </ListGroup.Item>
            );
          })}
          {displayBlockPricingNote && (
            <p className={css(styles.muted)}>
              *Estimated cost may change based on block pricing rates.
            </p>
          )}
          {props.displayBetaTerms && (
            <ListGroup.Item className={css(styles.listItem)}>
              <BetaTermsNotice
                betaTermsAccepted={props.betaTermsAccepted}
                betaTermsDisplayDate={props.betaTermsDisplayDate}
                setBetaTermsAccepted={props.setBetaTermsAccepted}
              />
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

SelectedServicesPanel.propTypes = {
  hasProofToFinalFile: PropTypes.bool,
  selectedServices: selectedServicesType,
  selectedTranslationType: PropTypes.string,
  showWarnings: PropTypes.bool,
  displayBetaTerms: PropTypes.bool,
  betaTermsAccepted: PropTypes.bool,
  setBetaTermsAccepted: PropTypes.func,
  betaTermsDisplayDate: PropTypes.instanceOf(Date),
};

const styles = StyleSheet.create({
  selectedServicesHeader: {
    backgroundColor: '#F6F6F6 !important',
    fontWeight: 'bold',
  },
  serviceDescriptions: {
    color: '#000000',
    opacity: 0.6,
  },
  link: {
    color: '#1a5a73',
  },
  listItem: {
    padding: '0px',
    cursor: 'initial',
    ':not(:first-child)': {
      padding: '10px 0px 10px 0px',
    },
    ':first-child': {
      padding: '0px 0px 10px 0px',
    },
    hover: {
      cursor: 'initial',
    },
  },
  muted: {
    color: '#000000',
    opacity: 0.6,
  },
});

export default SelectedServicesPanel;
