import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

import WithIcon from '~/components/app/common/WithIcon';

import { appStyles } from '~/components/app/common/styles';
import { userLogger } from '~/logic/UserLogger';

import {
  upgradeTranscriptionPath,
  upgradeLiveTranscriptionPath,
  aiDubbingPath,
  audioDescriptionPath,
  audioMixingPath,
  broadcastScriptsPath,
  captionPlacementPath,
  captionEncodingPath,
  dubbingPath,
  subtitlingPath,
  translationPath,
  viccPath,
  transcriptReviewPath,
  voiceArtistAudioDescriptionPath,
} from '~/helpers/app/paths';

function orderDropdown(props) {
  let currentKey = 1;
  const actions = [
    props.orderActions.upgradeToTranscription && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={upgradeTranscriptionPath(props.fileId)}
      >
        <i className="fa fa-level-up" /> Upgrade to Full Transcription
      </Dropdown.Item>
    ),
    props.orderActions.liveUpgradeToTranscription && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={upgradeLiveTranscriptionPath(props.fileId, props.fileName)}
      >
        <i className="fa fa-level-up" /> Upgrade to Full Transcription
      </Dropdown.Item>
    ),
    props.orderActions.aiDubbing && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={aiDubbingPath(props.fileId)}
      >
        <i className="fa fa-microphone" /> AI Dubbing
      </Dropdown.Item>
    ),
    props.orderActions.audioDescription && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={audioDescriptionPath(props.fileId)}
      >
        <i className="fa fa-audio-description" /> Audio Description
      </Dropdown.Item>
    ),
    props.orderActions.audioMixing && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={audioMixingPath(props.fileId)}
      >
        <i className="fa fa-sliders" /> Audio Mixing
      </Dropdown.Item>
    ),
    props.orderActions.broadcastScripts && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={broadcastScriptsPath(props.fileId)}
      >
        Broadcast Scripts
      </Dropdown.Item>
    ),
    props.orderActions.captionPlacement && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={captionPlacementPath(props.fileId)}
      >
        <i className="fa fa-sort-amount-desc" /> Caption Placement
      </Dropdown.Item>
    ),
    props.orderActions.captionEncoding && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={captionEncodingPath(props.fileId)}
      >
        <i className="fa fa-cc" /> Caption Encoding
      </Dropdown.Item>
    ),
    props.orderActions.dubbing && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={dubbingPath(props.fileId)}
      >
        <i className="fa fa-microphone" /> Dubbing
      </Dropdown.Item>
    ),
    props.orderActions.subtitling && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={subtitlingPath(props.fileId)}
      >
        <i className="fa fa-globe" /> Subtitling
      </Dropdown.Item>
    ),
    props.orderActions.amaraTranslation && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        onClick={() => props.setShowModal.amaraOrder(true)}
      >
        Amara Translation
      </Dropdown.Item>
    ),
    props.orderActions.translation && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={translationPath(props.fileId)}
      >
        <i className="fa fa-globe" /> Translation
      </Dropdown.Item>
    ),
    props.orderActions.vicc && props.enabledFeatures.includes('vicc') && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={viccPath(props.fileId)}
      >
        <i className="fa fa-search" /> ViCC
      </Dropdown.Item>
    ),
    props.orderActions.transcriptReview && props.enabledFeatures.includes('transcript_review') && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={transcriptReviewPath(props.fileId)}
      >
        <i className="fa fa-edit" /> Transcript Review
      </Dropdown.Item>
    ),
    props.orderActions.voiceArtistAudioDescription && (
      <Dropdown.Item
        key={`${currentKey++}`}
        className={css(appStyles.dropdownLink)}
        href={voiceArtistAudioDescriptionPath(props.fileId)}
      >
        <i className="fa fa-audio-description" /> Voice Artist Audio Description
      </Dropdown.Item>
    ),
  ];
  return actions;
}

function OrderServicesButton(props) {
  function getErrors(props) {
    if (!props.fileHasSource && !props.orderActions.liveUpgradeToTranscription) {
      return ['Additional services cannot be ordered until a media file is added'];
    }

    if (props.isDelinquent) {
      return ["Your account has a delinquent invoice that must be paid in order to receive full access to your account."]
    }

    return props.reprocessingAssets?.map((asset) => `${asset}s are still processing`) || [];
  }

  const errors = getErrors(props);
  const disabled = errors.length > 0;

  return (
    <TooltipIfErrors title="Ordering unavailable:" errors={errors}>
      <DropdownButton
        onClick={() => userLogger.logEvent('FileShow', 'Order Dropdown')}
        size="sm"
        disabled={disabled}
        id="dropdown-basic-button"
        className={css(disabled && styles.disabled, styles.orderMoreButton)}
        data-testid="orderDropdown"
        title={<WithIcon icon="fa fa-shopping-cart">Order More</WithIcon>}
        focusFirstItemOnShow="keyboard"
      >
        {props.orderActions && orderDropdown(props)}
      </DropdownButton>
    </TooltipIfErrors>
  );
}

const styles = StyleSheet.create({
  orderMoreButton: {
    float: 'right',
    marginRight: '13px',
    marginTop: '-4px',
  },
  disabled: {
    pointerEvents: 'none',
  },
});

OrderServicesButton.propTypes = {
  fileHasSource: PropTypes.bool,
  fileId: PropTypes.string,
  fileName: PropTypes.string,
  orderActions: PropTypes.object,
  setShowModal: PropTypes.shape({
    amaraOrder: PropTypes.func,
  }),
  reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  isDelinquent: PropTypes.bool
};

export default OrderServicesButton;
