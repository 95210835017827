import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['template', 'container'];
  static values = {
    index: Number,
  };

  connect() {
    this.indexValue = this.containerTarget.children.length;
  }

  add(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, this.indexValue);
    this.containerTarget.insertAdjacentHTML('beforeend', content);
    this.indexValue++;
  }

  remove(event) {
    event.preventDefault();
    const item = event.target.closest('.nested-item');
    item.remove();
  }
}
