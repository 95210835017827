import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Container from 'react-bootstrap/Container';

import RejectionNote from './RejectionNote';
import ServiceCardHeader from './ServiceCardHeader';

import AIDubbingServiceCard from './AIDubbingServiceCard';
import AlignmentServiceCard from './AlignmentServiceCard';
import AmaraCard from './AmaraCard';
import AsrServiceCard from './AsrServiceCard';
import AudioDescriptionImportServiceCard from './AudioDescriptionImportServiceCard';
import AudioDescriptionServiceCard from './AudioDescriptionServiceCard';
import AudioMixingServiceCard from './AudioMixingServiceCard';
import AutomatedCaptionPlacementServiceCard from './AutomatedCaptionPlacementServiceCard';
import BroadcastScriptsServiceCard from './BroadcastScriptsServiceCard';
import CaptionEncodingServiceCard from './CaptionEncodingServiceCard';
import CaptionImportServiceCard from './CaptionImportServiceCard';
import CaptionReformatServiceCard from './CaptionReformatServiceCard';
import CaptionRevisionServiceCard from './CaptionRevisionServiceCard';
import DescriptiveTranscriptServiceCard from './DescriptiveTranscriptServiceCard';
import DubbingServiceCard from './DubbingServiceCard';
import LiveServiceCard from './LiveServiceCard';
import ManualCaptionPlacementServiceCard from './ManualCaptionPlacementServiceCard';
import SubtitlingServiceCard from './SubtitlingServiceCard';
import TranscriptionServiceCard from './TranscriptionServiceCard';
import TranscriptReviewServiceCard from './TranscriptReviewServiceCard';
import TranslationServiceCard from './TranslationServiceCard';
import VideoClipCaptioningServiceCard from './VideoClipCaptioningServiceCard';
import VideoClipCaptioningParentServiceCard from './VideoClipCaptioningParentServiceCard';

import { isMultiServiceType } from '~/logic/services';
import { reviewAlignmentPath } from '~/helpers/app/paths';
import { threeplayApi } from '~/logic/ThreeplayApi';

function serviceCardComponent(serviceGroup) {
  switch (serviceGroup.name) {
    case 'AI Dubbing':
      return AIDubbingServiceCard;
    case 'Alignment':
      return AlignmentServiceCard;
    case 'Amara Translation':
      return AmaraCard;
    case 'ASR Service':
      return AsrServiceCard;
    case 'Audio Description Import':
      return AudioDescriptionImportServiceCard;
    case 'Audio Description':
    case 'Audio Description Narration':
    case 'Audio Description Revision':
    case 'Voice Artist Audio Description':
    case 'Voice Artist Audio Description Reformat':
      return AudioDescriptionServiceCard;
    case 'Audio Mixing':
      return AudioMixingServiceCard;
    case 'Automated Caption Placement':
      return AutomatedCaptionPlacementServiceCard;
    case 'Broadcast Scripts':
    case 'Broadcast Scripts Revision':
      return BroadcastScriptsServiceCard;
    case 'Caption Encoding':
      return CaptionEncodingServiceCard;
    case 'Caption Import':
      return CaptionImportServiceCard;
    case 'Caption Reformat':
      return CaptionReformatServiceCard;
    case 'Caption Revision':
      return CaptionRevisionServiceCard;
    case 'Descriptive Transcript':
      return DescriptiveTranscriptServiceCard;
    case 'Dubbing':
    case 'Dubbing Revision':
      return DubbingServiceCard;
    case 'Automatic Live Captioning':
    case 'Professional Live Captioning':
      return LiveServiceCard;
    case 'Manual Caption Placement':
      return ManualCaptionPlacementServiceCard;
    case 'Subtitling':
      return SubtitlingServiceCard;
    case 'Transcription and Captioning':
    case 'Other Language Transcription':
      return TranscriptionServiceCard;
    case 'Transcript Review':
      return TranscriptReviewServiceCard;
    case 'Translation':
    case 'Translation Revision':
      return TranslationServiceCard;
    case 'Video Clip Captioning':
      return VideoClipCaptioningServiceCard;
    case 'Video Clip Captioning Children':
      return VideoClipCaptioningParentServiceCard;
  }
}

function serviceCount(serviceGroup) {
  switch (serviceGroup.name) {
    case 'AI Dubbing':
    case 'Amara Translation':
    case 'Broadcast Scripts':
    case 'Broadcast Scripts Revision':
    case 'Caption Import':
    case 'Caption Encoding':
    case 'Dubbing':
    case 'Dubbing Revision':
    case 'Translation':
    case 'Translation Revision':
      return serviceGroup.services.length;
    case 'Video Clip Captioning Children': {
      const parent = (serviceGroup.services && serviceGroup.services[0]) || {};
      return parent.childGroup?.services.length;
    }
    default:
      return null;
  }
}

function serviceGroupStatus(serviceGroup) {
  if (serviceGroup.name === 'Video Clip Captioning Children') {
    const parent = (serviceGroup.services && serviceGroup.services[0]) || {};
    return parent.childGroup?.status;
  } else {
    return serviceGroup.status;
  }
}

function ServiceCard(props) {
  const serviceGroup = props.serviceGroup || {};
  const services = serviceGroup.services || [];
  const multi = isMultiServiceType(serviceGroup.name);
  const cardProps = multi
    ? { services: services, fileData: props.fileData, userTimeZone: props.userTimeZone }
    : { service: services[0], fileData: props.fileData, userTimeZone: props.userTimeZone };
  const status = serviceGroupStatus(serviceGroup);
  const CardContents = serviceCardComponent(serviceGroup);
  if (!CardContents) {
    return null;
  }

  const rejections = services
    .filter((service) => service.status === 'rejected')
    .map((service) => service.rejection || {});

  function serviceCardActions(serviceGroup) {
    if (
      serviceGroup.name === 'Audio Description' &&
      ['pending', 'in_progress', 'complete'].includes(serviceGroup.status)
    ) {
      return [
        <span key="add-ad-cheatsheet" onClick={handleAddAdCheatsheet}>
          Add Instructions
        </span>,
      ];
    } else if (
      serviceGroup.name === 'Alignment' &&
      ['alignment_review'].includes(serviceGroup.status)
    ) {
      return [
        <a key="alignment-review" href={reviewAlignmentPath(props.fileData.id)}>
          {' '}
          Review Transcript{' '}
        </a>,
      ];
    } else if (serviceGroup.name === 'Amara Translation') {
      return [
        <a key="amara-sync" href="#" onClick={handleSyncAmara}>
          {' '}
          <i className="fa fa-refresh" /> Sync With Amara{' '}
        </a>,
      ];
    }
  }

  function handleAddAdCheatsheet() {
    props.setShowModal.cheatSheet(true, 'AudioDescriptionService');
  }

  async function handleSyncAmara() {
    const { data: { syncAmaraTranslations: { errors, successes } = {} } = {} } =
      await threeplayApi.mutate(syncAmaraTranslationsMutation(props.fileData.id));
    if (errors) {
      props.setErrorsAlert(errors);
    } else {
      props.setSuccess(successes);
    }
  }

  function syncAmaraTranslationsMutation(fileId) {
    return `syncAmaraTranslations( fileId: ${fileId}) {
      errors,
      successes,
    }`;
  }

  const randomId = Math.floor(Math.random() * 50) + 1;
  return (
    <div className={css(styles.serviceCard)}>
      <Container fluid>
        <ServiceCardHeader
          actions={serviceCardActions(serviceGroup)}
          cancellable={props.cancellable}
          enabledFeatures={props.enabledFeatures}
          open={props.open}
          percentComplete={services[0].percentComplete}
          randomId={randomId}
          serviceType={serviceGroup.name}
          services={services}
          serviceCount={serviceCount(serviceGroup)}
          status={status}
          setOpen={props.setOpen}
          setShowModal={props.setShowModal}
          showGetItNow={props.showGetItNow}
          showProgressBar={props.showProgressBar}
        ></ServiceCardHeader>
        <div
          className={css(props.open ? styles.open : styles.closed)}
          id={`serviceCard-${randomId}`}
        >
          {rejections.length > 0 && <RejectionNote rejections={rejections} />}
          <CardContents
            {...cardProps}
            isInternalUser={props.isInternalUser}
            isManagedAccount={props.isManagedAccount}
            onUpdate={props.onUpdate}
            setErrorsAlert={props.setErrorsAlert}
            setShowModal={props.setShowModal}
          />
        </div>
      </Container>
    </div>
  );
}

ServiceCard.propTypes = {
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  isInternalUser: PropTypes.bool,
  isManagedAccount: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setShowModal: PropTypes.shape({
    cheatSheet: PropTypes.func,
    editEncodedCaptions: PropTypes.func,
    downloadAd: PropTypes.func,
  }),
  setOpen: PropTypes.func,
  setErrorsAlert: PropTypes.func,
  setSuccess: PropTypes.func,
  serviceGroup: PropTypes.shape({
    displayType: PropTypes.string,
    status: PropTypes.string,
  }),
  showGetItNow: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  fileData: PropTypes.shape({
    id: PropTypes.string,
    reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  }),
  cancellable: PropTypes.bool,
  userTimeZone: PropTypes.string,
};

const styles = StyleSheet.create({
  closed: {
    display: 'none',
  },
  open: {},
  serviceCard: {
    padding: '8px 4px 8px 3px',
    borderBottom: '1px solid #ccc',
    width: '100%',
  },
});

export default ServiceCard;
