import React, { useReducer, createContext, useContext } from 'react';
import {
  ServiceLevel,
  SolutionType,
  TurnaroundLevel,
  OrderOptions as BaseOrderOptions,
} from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';

import { OutputAsset } from 'components/app/order_more/components/OutputAssetSelector';

type OrderOptionsAction =
  | { type: 'SET_SOLUTION_TYPE'; payload: SolutionType }
  | { type: 'SET_SERVICE_LEVEL'; payload: ServiceLevel }
  | { type: 'SET_TURNAROUND_LEVEL'; payload: TurnaroundLevel }
  | { type: 'SET_OUTPUT_ASSETS'; payload: OutputAsset[] };

// The OrderForm's AudioDescriptionOrderOptions has a differrent structure for output assets. We should eventually
// choose one structure and use that throughout the app but this will keep alignment for now.
interface OrderMoreOptions extends BaseOrderOptions {
  outputAssets: OutputAsset[];
}

interface OrderOptionsContextType {
  orderOptions: OrderMoreOptions;
  dispatch: React.Dispatch<OrderOptionsAction>;
}

const OrderOptionsContext = createContext<OrderOptionsContextType | undefined>(undefined);

const orderOptionsReducer = (
  state: OrderMoreOptions,
  action: OrderOptionsAction
): OrderMoreOptions => {
  switch (action.type) {
    case 'SET_SOLUTION_TYPE':
      return { ...state, speakerType: action.payload };
    case 'SET_SERVICE_LEVEL':
      return { ...state, serviceLevel: action.payload };
    case 'SET_TURNAROUND_LEVEL':
      return { ...state, turnaroundLevel: action.payload };
    case 'SET_OUTPUT_ASSETS':
      return { ...state, outputAssets: action.payload };
    default:
      return state;
  }
};

interface InitialOptionsType {
  solutionTypes: SolutionType[];
  serviceLevels: ServiceLevel[];
  turnaroundLevels: TurnaroundLevel[];
}

interface OrderOptionsProviderProps {
  children: React.ReactNode;
  initialOptions: InitialOptionsType;
}

export const OrderOptionsProvider = ({ children, initialOptions }: OrderOptionsProviderProps) => {
  const initialState: OrderMoreOptions = {
    speakerType:
      initialOptions?.solutionTypes?.length > 0 ? initialOptions.solutionTypes[0] : undefined,
    serviceLevel:
      initialOptions?.serviceLevels?.length > 0 ? initialOptions.serviceLevels[0] : undefined,
    turnaroundLevel:
      initialOptions?.turnaroundLevels?.length > 0 ? initialOptions.turnaroundLevels[0] : undefined,
    outputAssets: [],
  };

  const [orderOptions, dispatch] = useReducer(orderOptionsReducer, initialState);

  return (
    <OrderOptionsContext.Provider value={{ orderOptions, dispatch }}>
      {children}
    </OrderOptionsContext.Provider>
  );
};

export const useOrderOptions = (): OrderOptionsContextType => {
  const context = useContext(OrderOptionsContext);
  if (context === undefined) {
    throw new Error('useOrderOptions must be used within an OrderOptionsProvider');
  }
  return context;
};

export const orderOptionsActions = {
  setSolutionType: (solutionTypes: SolutionType[], name: string) => {
    const selectedType = findOptionByName(solutionTypes, name, 'Solution type');
    return { type: 'SET_SOLUTION_TYPE', payload: selectedType } as const;
  },

  setServiceLevel: (serviceLevels: ServiceLevel[], name: string) => {
    const serviceLevel = findOptionByName(serviceLevels, name, 'Service level');
    return { type: 'SET_SERVICE_LEVEL', payload: serviceLevel } as const;
  },

  setTurnaroundLevel: (turnaroundLevels: TurnaroundLevel[], id: string) => {
    const selectedLevel = findOptionById(turnaroundLevels, id, 'Turnaround level');
    return { type: 'SET_TURNAROUND_LEVEL', payload: selectedLevel } as const;
  },

  setOutputAssets: (outputAssets: OutputAsset[]) => {
    return { type: 'SET_OUTPUT_ASSETS', payload: outputAssets } as const;
  },
};

function findOptionByName<T extends { name: string }>(
  options: T[],
  name: string,
  optionType: string
): T {
  const option = options.find((opt) => opt.name === name);
  if (!option && options.length > 0) {
    throw new Error(`${optionType} with name ${name} not found`);
  }
  return option as T;
}

function findOptionById<T extends { id: string }>(options: T[], id: string, optionType: string): T {
  const option = options.find((opt) => opt.id === id);
  if (!option && options.length > 0) {
    throw new Error(`${optionType} with id ${id} not found`);
  }
  return option as T;
}
