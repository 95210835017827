import React from 'react';
import { css } from 'aphrodite';
import { Card, OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap';
import { appStyles } from '~/components/app/common/styles';
import TurnaroundLevelCard from '~/components/app/order_form/TurnaroundLevelCard';
import { AD_TURNAROUND_START_INDEX } from '~/helpers/constants';
import { OrderOptions } from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';
interface TurnaroundLevel {
  id: string;
  name: string;
  displayName: string;
  price?: number;
  surcharge: number;
  deliveryDate: string;
  serviceType: string;
}

interface TurnaroundTimeSectionProps {
  displayableTurnaroundLevels: TurnaroundLevel[];
  orderOptions: OrderOptions;
  setTurnaroundLevel: (turnaroundLevelId: string) => void;
}

export const getDisplayableTurnaroundLevels = (
  availableTurnaroundLevels: TurnaroundLevel[],
  isVoiceArtistAD: boolean,
  isAiOnly: boolean,
  voiceArtistServiceType = 'AudioDescriptions::VoiceOvers::VoiceOverAudioDescriptionService'
) => {
  if (isAiOnly) {
    return [];
  } else if (isVoiceArtistAD) {
    return availableTurnaroundLevels.filter((tl) => tl.serviceType === voiceArtistServiceType);
  } else {
    return availableTurnaroundLevels.filter((tl) => tl.serviceType === 'AudioDescriptionService');
  }
};

const TurnaroundTimeSection: React.FC<TurnaroundTimeSectionProps> = ({
  displayableTurnaroundLevels,
  orderOptions,
  setTurnaroundLevel,
}) => {
  if (!displayableTurnaroundLevels?.length) return null;

  return (
    <>
      <Card.Title className={css(appStyles.title)}>
        Audio Description Turnaround Time&nbsp;
        <OverlayTrigger
          placement="auto"
          trigger={['hover', 'focus']}
          delay={{ show: 0, hide: 2000 }}
          overlay={
            <Popover id="audio-description-delivery-popover">
              <PopoverContent>
                The delivery date for your audio description is separate from the delivery of your
                main service.
              </PopoverContent>
            </Popover>
          }
        >
          <i className="fa fa-info-circle"></i>
        </OverlayTrigger>
      </Card.Title>
      <div className="d-flex">
        {displayableTurnaroundLevels.map((turnaround, index) => (
          <TurnaroundLevelCard
            id={turnaround.id}
            key={turnaround.name}
            index={index + AD_TURNAROUND_START_INDEX}
            name={turnaround.name}
            displayName={turnaround.displayName}
            surcharge={turnaround.surcharge}
            deliveryDate={turnaround.deliveryDate}
            turnaroundDisabled={false}
            turnaroundDisabledTooltip={''}
            setTurnaroundLevel={setTurnaroundLevel}
            orderOptions={orderOptions}
          />
        ))}
      </div>
    </>
  );
};

export default TurnaroundTimeSection;
