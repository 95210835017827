import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ManagePlanStepContainer from './ManagePlanStepContainer';
import { MANAGE_PLAN_WIZARD_STEP_INDEX } from '~/helpers/constants';
import ChoosePlan from './steps/ChoosePlan';
import Confirmation from './steps/Confirmation';
import Payment from './steps/Payment';
import { createAccountPlanMutation } from './data/mutations';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { numberToCurrency } from '~/helpers/numbers';
import { refreshModalDataQuery } from './data/queries';

function ManagePlanWizard(props) {
  const [autoRenew, setAutoRenew] = useState(true);
  const [billingInformation, setBillingInformation] = useState(null);
  const [checkedTerms, setCheckedTerms] = useState({
    commitment: false,
    cancellation: false,
    terms: false,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepType, setCurrentStepType] = useState('choosePlan');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [showInvoiceBillingForm, setShowInvoiceBillingForm] = useState(false);
  const [paymentAccount, setPaymentAccount] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [paymentAccounts, setPaymentAccounts] = useState(null);

  useEffect(() => {
    fetchManagePlanData();
  }, []);

  useEffect(() => {
    const stepName = MANAGE_PLAN_WIZARD_STEP_INDEX[currentStep];
    setCurrentStepType(stepName);
  }, [currentStep]);

  function fetchManagePlanData() {
    threeplayApi.request(refreshModalDataQuery).then((response) => {
      if (response?.data?.project) {
        setPaymentAccounts(response.data.project.paymentAccounts);
        setBillingInformation(response.data.project.billingInformation);
      } else if (response?.errors) {
        //TODO where to show these errors?  On the modal? On the payment screen?
      }
    });
  }

  function hasValidCard() {
    // TODO refactor into state and effect pattern
    let validCard = false;
    if (paymentAccounts?.length > 0) {
      paymentAccounts.forEach((payment) => {
        if (payment.validCard) {
          validCard = true;
        }
      });
    }
    return validCard;
  }

  function determineCurrentStepErrors() {
    const errors = [];
    switch (currentStepType) {
      case 'choosePlan':
        if (!selectedPlan) {
          errors.push('Please select a plan');
        } else if (selectedPlan === 'pro' && !selectedFrequency) {
          errors.push('You must select a billing frequency for the Pro plan');
        }
        return errors;
      case 'payment':
        if (selectedFrequency === 'annual') {
          if (!hasValidCard() && !billingInformation?.validBillingAddress) {
            errors.push('You must have a valid credit card or billing address for a Pro plan');
          }
          if (paymentType === 'payment_account') {
            if (!paymentAccount) {
              errors.push('Please select a valid credit card to be billed.');
            }
          } else if (paymentType === 'invoice') {
            if (!billingInformation?.validBillingAddress) {
              errors.push(
                'We must have a valid billing address on file. Please update the address.'
              );
            }
            if (showInvoiceBillingForm) {
              errors.push('Please cancel or save your billing information changes to proceed.');
            }
          } else {
            errors.push('Please select a payment option to be billed.');
          }
        }
        return errors;
      case 'confirmation':
        if (!(checkedTerms.commitment && checkedTerms.cancellation && checkedTerms.terms)) {
          errors.push('You must agree to all terms to create a Pro Account');
        }
        return errors;
    }
  }

  function calculatePrice({ renewal = false }) {
    const price = renewal ? props.defaultPrice : props.introPrice;
    return numberToCurrency.format(price);
  }

  const submit = () => {
    props.setSubmitting(true);
    threeplayApi
      .request(createAccountPlanMutation, {
        plan: {
          paymentAccount: paymentAccount,
          paymentType: paymentType,
          frequency: selectedFrequency,
          autoRenew: autoRenew,
        },
      })
      .then((response) => {
        response.errors ? props.handleCreatePlanErrors() : props.handleCreatePlanSuccess();
        props.setSubmitting(false);
      });
  };

  return (
    <ManagePlanStepContainer
      currentStep={currentStep}
      currentStepType={currentStepType}
      enableNext={determineCurrentStepErrors().length === 0}
      enableSubscribe={determineCurrentStepErrors().length === 0 && !props.submitting}
      setCurrentStep={setCurrentStep}
      submit={submit}
      stepErrors={determineCurrentStepErrors()}
    >
      {currentStepType === 'choosePlan' && (
        <ChoosePlan
          autoRenew={autoRenew}
          calculatePrice={calculatePrice}
          selectedFrequency={selectedFrequency}
          selectedPlan={selectedPlan}
          setAutoRenew={setAutoRenew}
          setSelectedFrequency={setSelectedFrequency}
          setSelectedPlan={setSelectedPlan}
        />
      )}
      {currentStepType === 'payment' && (
        <Payment
          authenticityToken={props.authenticityToken}
          billingInformation={billingInformation}
          clientToken={props.clientToken}
          fetchManagePlanData={fetchManagePlanData}
          hasValidCard={hasValidCard}
          paymentAccount={paymentAccount}
          paymentAccounts={paymentAccounts}
          paymentType={paymentType}
          selectedFrequency={selectedFrequency}
          setBillingInformation={setBillingInformation}
          setPaymentAccount={setPaymentAccount}
          setPaymentType={setPaymentType}
          setShowInvoiceBillingForm={setShowInvoiceBillingForm}
          showInvoiceBillingForm={showInvoiceBillingForm}
          siteKey={props.siteKey}
        />
      )}
      {currentStepType === 'confirmation' && (
        <Confirmation
          autoRenew={autoRenew}
          calculatePrice={calculatePrice}
          checkedTerms={checkedTerms}
          selectedFrequency={selectedFrequency}
          selectedPlan={selectedPlan}
          setCheckedTerms={setCheckedTerms}
          termsDocument={props.termsDocument}
        />
      )}
    </ManagePlanStepContainer>
  );
}

ManagePlanWizard.propTypes = {
  authenticityToken: PropTypes.string,
  clientToken: PropTypes.string,
  defaultPrice: PropTypes.number,
  handleCreatePlanErrors: PropTypes.func,
  handleCreatePlanSuccess: PropTypes.func,
  introPrice: PropTypes.number,
  setSubmitting: PropTypes.func,
  siteKey: PropTypes.string,
  submitting: PropTypes.bool,
  termsDocument: PropTypes.object,
};

export default ManagePlanWizard;
