import React from 'react';
import { OutputAsset } from '~/components/app/order_more/components/OutputAssetSelector';

interface OutputAssetSummaryProps {
  selectedOutputAssets: OutputAsset[];
}

const OutputAssetSummary = ({ selectedOutputAssets }: OutputAssetSummaryProps) => {
  return (
    <div>
      <h5>Output Formats Ordered</h5>
      {selectedOutputAssets.length === 0 ? (
        <p>No output assets selected</p>
      ) : (
        <ul>
          {selectedOutputAssets.map((mix) => (
            <>
              <li key={mix.code}>{mix.adDisplayName}</li>
              <ul>{mix.embeds?.map((embed) => <li key={embed.code}>{embed.adDisplayName}</li>)}</ul>
            </>
          ))}
        </ul>
      )}
    </div>
  );
};

export default OutputAssetSummary;
