import { useOrderOptions, orderOptionsActions } from './OrderOptionsContext';
import {
  ServiceLevel,
  SolutionType,
  TurnaroundLevel,
} from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';

interface AvailableOptionsType {
  solutionTypes: SolutionType[];
  serviceLevels: ServiceLevel[];
  turnaroundLevels: TurnaroundLevel[];
}

/**
 * Custom hook to handle solution type changes with appropriate defaults
 * for service level and turnaround time
 * Logic taken from AudioDescriptionOrderOptions.tsx
 */
export const useSolutionTypeChange = (availableOptions: AvailableOptionsType) => {
  const { dispatch } = useOrderOptions();

  const changeSolutionType = (name: string) => {
    const { solutionTypes, serviceLevels, turnaroundLevels } = availableOptions;

    // Set the solution type
    dispatch(orderOptionsActions.setSolutionType(solutionTypes, name));

    // Find appropriate defaults based on solution type
    const isVoiceArtist = name === 'Voice Artist';
    const serviceType = isVoiceArtist
      ? 'AudioDescriptions::VoiceOvers::VoiceOverAudioDescriptionService'
      : 'AudioDescriptionService';

    // Set standard service level if available
    const standardServiceLevel = serviceLevels.find((sl) => sl.name === 'Standard');
    if (standardServiceLevel) {
      dispatch(orderOptionsActions.setServiceLevel(serviceLevels, 'Standard'));
    }

    // Set standard turnaround level if available
    const standardTurnaround = turnaroundLevels.find(
      (tl) => tl.name.includes('Standard') && tl.serviceType === serviceType
    );
    if (standardTurnaround) {
      dispatch(orderOptionsActions.setTurnaroundLevel(turnaroundLevels, standardTurnaround.id));
    }
  };

  return changeSolutionType;
};

export default useSolutionTypeChange;
