import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Card from 'react-bootstrap/Card';

import { checkKeyPress } from '~/helpers/checkKeyPress';
import selectedStyles from '../../../styles/selectedDeselectedStyles';

import type {
  SolutionType,
  OrderOptions,
} from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';

interface SolutionTypeCardProps {
  index?: number;
  solutionType: SolutionType;
  setSolutionType: (name: string) => void;
  orderOptions: OrderOptions;
  className?: string;
  showBeta: boolean;
}

const LEARN_MORE_URLS: Record<string, string> = {
  Synthesized:
    'https://support.3playmedia.com/hc/en-us/articles/115010653947-What-Is-Audio-Description-',
  'Voice Artist':
    'https://support.3playmedia.com/hc/en-us/articles/115010653947-What-Is-Audio-Description-',
};

function SolutionTypeCard({
  index,
  solutionType,
  setSolutionType,
  orderOptions,
  showBeta,
  className,
}: SolutionTypeCardProps) {
  const { name, displayPrice, description } = solutionType;
  return (
    <Card
      bg="light"
      data-testid={name}
      tabIndex={index}
      style={{ width: '20rem' }}
      onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
        checkKeyPress(e, () => setSolutionType(name), { triggerOnSpace: true })
      }
      onClick={() => setSolutionType(name)}
      className={
        className || (orderOptions.speakerType && orderOptions.speakerType.name === name)
          ? css(selectedStyles.selectedWithoutMargin, styles.box)
          : css(selectedStyles.deselectedWithoutMargin, styles.box)
      }
    >
      <Card.Body>
        <div>
          <div className={css(styles.left)}>
            <b>{name}</b>
            {showBeta && <span className="badge badge-danger ml-1">Beta</span>}
          </div>
          &nbsp;
          <b className={css(styles.right)}>{displayPrice}</b>
        </div>
        <div className={css(styles.inline)}>{description}</div>
        <br />
        {LEARN_MORE_URLS[name] && (
          <a href={LEARN_MORE_URLS[name]} rel="noreferrer" target="_blank">
            Learn More
          </a>
        )}
      </Card.Body>
    </Card>
  );
}

const styles = StyleSheet.create({
  box: {
    'margin-right': '1rem',
    'margin-bottom': '0.5rem',
    cursor: 'pointer',
    'max-width': '25rem',
    'min-width': '15rem',
  },
  right: {
    float: 'right',
    'font-size': '.9rem',
    'font-weight': 'bold',
    color: '#4c4c4c',
  },
  left: {
    float: 'left',
  },
  inline: {
    display: 'inline-block',
    'font-size': '.9rem',
    'margin-top': '.2rem',
  },
});

export default SolutionTypeCard;
