import React, { useContext, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import { threeplayApi } from '~/logic/ThreeplayApi';
import TurnaroundLevelCard from '../TurnaroundLevelCard';
import LanguageSelector from '../LanguageSelector';
import { primaryServiceDetailsQuery } from '~/components/app/order_form/data/queries';
import {
  ENGLISH_ID,
  TRANSPERFECT_VENDOR_ID,
  TURNAROUND_LEVEL_START_INDEX,
} from '~/helpers/constants';
import { displayPrice, numberToCurrency } from '~/helpers/numbers';
import { isVendorTranscriptionLanguage } from '../helpers/selectedServicesHelpers';
import { ProjectContext } from '../OrderForm';

function TranscriptionOrderOptions(props) {
  const [availableTurnaroundLevels, setAvailableTurnaroundLevels] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [errors, setErrors] = useState([]);
  const { features, liveUpgradeDetails, additionalJumpstartLanguageIds } =
    useContext(ProjectContext);

  const selectedLanguage = props.orderOptions.language?.fullName || 'English';
  const selectedLanguageId = props.orderOptions.language?.ids || [ENGLISH_ID];
  const selectedLanguagePrice = displayPrice(
    props.orderOptions?.language?.price,
    props.orderOptions?.language?.unit,
    false
  );

  function setLanguage(languageName) {
    setFetching(true);
    threeplayApi
      .request(primaryServiceDetailsQuery, {
        selectedServiceType: 'Transcription',
        languageType: languageName,
      })
      .then((res) => {
        const data = res.data || {};
        const availableOrderOptions = data.project?.orderableServices[0].orderOptions;
        if (availableOrderOptions) {
          const turnaroundLevels = availableOrderOptions.transcriptionTurnaroundOptions.map(
            (turnaround) => turnaround.turnaroundLevel
          );
          setAvailableTurnaroundLevels(turnaroundLevels);
          const polishedLanguages = parseLanguages(
            availableOrderOptions.transcriptionServiceOptions
          );

          const language = polishedLanguages.find((s) => s.fullName === languageName);
          const defaultTurnaroundLevel = availableOrderOptions.transcriptionTurnaroundOptions.find(
            (turnaround) => turnaround.default === true
          ).turnaroundLevel;

          // Are we really just reverting to defaults rather than trying to maintain existing selections?
          updateOrderOptions(language, defaultTurnaroundLevel);
        } else {
          setErrors(res.errors);
        }
      });
    setFetching(false);
  }

  function setTurnaroundLevel(turnaroundLevelId) {
    const turnaround = availableTurnaroundLevels.find((tl) => tl.id === turnaroundLevelId);
    props.updateOrderOptions('Transcription', {
      ...props.orderOptions,
      turnaroundLevel: {
        id: turnaroundLevelId,
        name: turnaround.name,
        displayName: turnaround.displayName,
        price: turnaround.surcharge,
        deliveryDate: turnaround.deliveryDate,
        maxFileDuration: turnaround.maxFileDuration,
      },
    });
  }

  function vendorSupportsMultipleTurnarounds() {
    return props.transcriptionVendorId === TRANSPERFECT_VENDOR_ID;
  }

  function parseLanguages(transcriptionServiceOptions) {
    const formattedLanguages = [];
    transcriptionServiceOptions.forEach(function (tso) {
      if (tso.languages.length > 1) {
        if (tso.languages[0].fullName === 'English') {
          formattedLanguages.push({
            ids: tso.languages.map((language) => parseInt(language.id)),
            fullName: 'Dual Language - English / Spanish',
            price: tso.price.amount,
            unit: tso.price.unit,
          });
        } else {
          formattedLanguages.push({
            ids: tso.languages.map((language) => parseInt(language.id)),
            fullName: 'Dual Language - Spanish / English',
            price: tso.price.amount,
            unit: tso.price.unit,
          });
        }
      } else {
        formattedLanguages.push({
          ids: [parseInt(tso.languages[0].id)],
          genericLanguageId: parseInt(genericLanguageId(tso.languages[0])),
          fullName: tso.languages[0].fullName,
          price: tso.price.amount,
          unit: tso.price.unit,
          originalPrice: tso.price.originalAmount
            ? numberToCurrency.format(tso.price.originalAmount)
            : null,
        });
      }
    });
    return formattedLanguages;
  }

  function genericLanguageId(language) {
    return language.genericLanguageId || language.id;
  }

  function updateOrderOptions(language, turnaroundLevel) {
    props.updateOrderOptions('Transcription', {
      language,
      turnaroundLevel: {
        ...turnaroundLevel,
        price: turnaroundLevel.surcharge,
      },
    });
  }

  function TurnaroundTitle() {
    return (
      <Card.Title className={css(styles.stepTitle)}>
        {features.other
          ? 'Select Turnaround Service Level for Transcription and Captioning'
          : 'Delivery Date'}
      </Card.Title>
    );
  }

  function CheatSheetTitle() {
    return (
      <Card.Title className={css(styles.stepTitle)}>
        Do you want to add a glossary of terms or order instructions to help our transcriptionists?
        (optional)
      </Card.Title>
    );
  }

  useEffect(() => {
    setFetching(true);
    threeplayApi
      .request(primaryServiceDetailsQuery, {
        selectedServiceType: 'Transcription',
        languageType: selectedLanguage,
        liveUpgradeFileId: liveUpgradeDetails?.liveFileId,
      })
      .then((res) => {
        const data = res.data || {};
        const availableOrderOptions = data.project?.orderableServices[0].orderOptions;
        if (availableOrderOptions) {
          const turnaroundLevels = availableOrderOptions.transcriptionTurnaroundOptions.map(
            (turnaround) => turnaround.turnaroundLevel
          );
          setAvailableTurnaroundLevels(turnaroundLevels);
          const polishedLanguages = parseLanguages(
            availableOrderOptions.transcriptionServiceOptions
          );
          setAvailableLanguages(polishedLanguages);

          if (!props.orderOptions.language) {
            const language = polishedLanguages.find((s) => s.fullName === selectedLanguage);
            const defaultTurnaroundLevel =
              availableOrderOptions.transcriptionTurnaroundOptions.find(
                (turnaround) => turnaround.default === true
              ).turnaroundLevel;
            updateOrderOptions(language, defaultTurnaroundLevel);
          }
        } else {
          setErrors(res.errors);
        }
      });
    setFetching(false);
  }, []);

  if (fetching) {
    return <div>loading....</div>;
  } else if (errors.length > 0) {
    return <div>[errors]</div>;
  } else {
    return (
      <div>
        <LanguageSelector
          languages={availableLanguages}
          selectedLanguage={selectedLanguage}
          setLanguage={setLanguage}
          price={selectedLanguagePrice}
        />
        {(!isVendorTranscriptionLanguage(selectedLanguageId, additionalJumpstartLanguageIds) ||
          vendorSupportsMultipleTurnarounds()) && (
          <div className="mt-4">
            <TurnaroundTitle />
            Estimated delivery times below are calculated for an order placed in the next few
            minutes.
            <div className={css(styles.flex)}>
              {availableTurnaroundLevels.map((turnaround, index) => (
                <TurnaroundLevelCard
                  id={turnaround.id}
                  index={index + TURNAROUND_LEVEL_START_INDEX}
                  key={turnaround.name}
                  name={turnaround.name}
                  displayName={turnaround.displayName}
                  price={turnaround.price}
                  surcharge={turnaround.surcharge}
                  deliveryDate={turnaround.deliveryDate}
                  setTurnaroundLevel={setTurnaroundLevel}
                  orderOptions={props.orderOptions}
                  turnaroundDisabled={!turnaround.active}
                  turnaroundDisabledTooltip="This turnaround service level is not available"
                />
              ))}
            </div>
          </div>
        )}
        {isVendorTranscriptionLanguage(selectedLanguageId, additionalJumpstartLanguageIds) &&
          props.transcriptionVendorId != TRANSPERFECT_VENDOR_ID && (
            <p className={css(styles.oltTurnaround)}>
              Note:
              {features.other ? ' Transcription and Captioning ' : ' Transcription '}
              for files in {selectedLanguage}
              {features.other ? ' are ' : ' is '}
              done through our third-party partners.
              <br />
              Turnaround times will vary and cannot be guaranteed by a certain date. Most files are
              delivered within ten business days, subject to audio quality, content difficulty, and
              language selection.
            </p>
          )}
        {!!features.transcription && !features.other && <CheatSheetTitle />}
        {props.cheatSheetComponent}
      </div>
    );
  }
}

TranscriptionOrderOptions.propTypes = {
  cheatSheetComponent: PropTypes.object,
  orderOptions: PropTypes.object,
  transcriptionVendorId: PropTypes.number,
  updateOrderOptions: PropTypes.func,
};

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    'flex-wrap': 'wrap',
    marginTop: '1rem',
  },
  stepTitle: {
    'font-weight': 'bold',
    'margin-bottom': '.25rem',
  },
  oltTurnaround: {
    marginTop: '.5rem',
  },
});

export default TranscriptionOrderOptions;
