import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  aiDubbingOrderOptionType,
  selectedServicesType,
  translationProfileType,
} from '~/components/app/order_form/propTypes';
import { ProjectContext } from '~/components/app/order_form/OrderForm';
import OrderOptionsContainer from '~/components/app/order_form/serviceOptions/OrderOptionsContainer';

import AIDubbingOrderOptions from './dubbing/AIDubbingOrderOptions';
import HumanDubbingOrderOptions from './dubbing/HumanDubbingOrderOptions';

const DubbingOrderOptions = ({
  cheatSheetComponent,
  dubbingWarningSelected,
  selectedServices,
  setDubbingWarningSelected,
  setValidDubbingQuote,
  translationProfiles,
  updateOrderOptions,
  validDubbingQuote,
}) => {
  const { accountType, aiDubbingOrderOptions, dubbingOrderOptions } = useContext(ProjectContext);
  const title = accountType.enterprise ? 'AI Dubbing' : 'Voice Artist Dubbing';

  const primaryService = selectedServices.find((service) => service.primary);
  const sourceLanguageId = primaryService.orderOptions.language.genericLanguageId;

  return (
    <OrderOptionsContainer title={title} componentName="DubbingOrderOptions">
      {accountType.enterprise && (
        <AIDubbingOrderOptions
          dubbingOrderOptions={aiDubbingOrderOptions}
          dubbingWarningSelected={dubbingWarningSelected}
          selectedServices={selectedServices}
          setDubbingWarningSelected={setDubbingWarningSelected}
          showDubbingRefundNotice
          sourceLanguageId={sourceLanguageId}
          translationProfiles={translationProfiles}
          updateOrderOptions={updateOrderOptions}
        />
      )}
      {accountType.studio && (
        <HumanDubbingOrderOptions
          cheatSheetComponent={cheatSheetComponent}
          dubbingOrderOptions={dubbingOrderOptions}
          selectedServices={selectedServices}
          setValidDubbingQuote={setValidDubbingQuote}
          updateOrderOptions={updateOrderOptions}
          validDubbingQuote={validDubbingQuote}
        />
      )}
    </OrderOptionsContainer>
  );
};

DubbingOrderOptions.propTypes = {
  cheatSheetComponent: PropTypes.func,
  dubbingWarningSelected: PropTypes.bool,
  selectedServices: selectedServicesType,
  setDubbingWarningSelected: PropTypes.func,
  setValidDubbingQuote: PropTypes.func,
  translationProfiles: PropTypes.arrayOf(translationProfileType),
  updateOrderOptions: PropTypes.func,
  validDubbingQuote: PropTypes.bool,
};

export default DubbingOrderOptions;
