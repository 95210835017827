import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { StyleSheet, css } from 'aphrodite';

import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';
import MultiServiceTable from './MultiServiceTable';

function DubbingServiceCard(props) {
  function handleAddCheatsheet() {
    props.setShowModal.cheatSheet(true, 'Dubbings::AI::DubbingService');
  }

  const headers = ['Language', 'Translation Type', 'Ordered'];
  // TODO we should probably restrict this to `cancellable` in future
  // once implemented on AI Dubbing services.
  const showAddCheatsheet = props.services.every((service) => service.status !== 'complete');

  return (
    <>
      {showAddCheatsheet && (
        <div
          className={css(styles.serviceAction)}
          key="add-transcription-cheatsheet"
          onClick={handleAddCheatsheet}
        >
          Add Order Instructions
        </div>
      )}

      <MultiServiceTable headers={headers}>
        {props.services.map((service) => (
          <tr key={service.id}>
            <td>{service.targetLanguage?.fullName ?? 'Unknown Language'}</td>
            <td>{service.translationType}</td>
            <td>
              {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
            </td>
          </tr>
        ))}
      </MultiServiceTable>
    </>
  );
}

DubbingServiceCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  setShowModal: PropTypes.shape({
    cheatSheet: PropTypes.func,
  }),
  service: PropTypes.shape({
    cancellable: PropTypes.bool,
  }),
  userTimeZone: PropTypes.string,
};

const styles = StyleSheet.create({
  serviceAction: {
    color: '#007EB5',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
});

export default DubbingServiceCard;
