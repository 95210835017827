import React from 'react';
import { css } from 'aphrodite';
import { Card } from 'react-bootstrap';
import { appStyles } from '~/components/app/common/styles';
import ServiceLevelCard from '~/components/app/order_form/ServiceLevelCard';
import { AD_OPTIONS_START_INDEX } from '~/helpers/constants';
import { OrderOptions } from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';

interface ServiceLevel {
  name: string;
  price: number;
  displayPrice: string;
  description: string;
  deliveryDate?: string;
  voiceArtistPrice?: number;
  voiceArtistDisplayPrice?: string;
  aiOnlyPrice?: number;
  aiOnlyDisplayPrice?: string;
}

export const getDisplayableServiceLevels = (
  availableServiceLevels: ServiceLevel[],
  isAiOnly: boolean
) => {
  if (isAiOnly) {
    return availableServiceLevels.filter((sl) => sl.name !== 'Choose for Me');
  } else {
    return availableServiceLevels;
  }
};

interface ServiceLevelSectionProps {
  displayableServiceLevels: ServiceLevel[];
  orderOptions: OrderOptions;
  setServiceLevel: (serviceLevel: string) => void;
}

function getPriceForSolutionType(level: ServiceLevel, speakerType: string) {
  if (speakerType === 'Voice Artist' && level.voiceArtistPrice !== undefined) {
    return {
      price: level.voiceArtistPrice,
      displayPrice: level.voiceArtistDisplayPrice,
    };
  } else if (speakerType === 'AI Only' && level.aiOnlyPrice !== undefined) {
    return {
      price: level.aiOnlyPrice,
      displayPrice: level.aiOnlyDisplayPrice,
    };
  } else {
    return {
      price: level.price,
      displayPrice: level.displayPrice,
    };
  }
}

const ServiceLevelSection: React.FC<ServiceLevelSectionProps> = ({
  displayableServiceLevels,
  orderOptions,
  setServiceLevel,
}) => (
  <>
    <Card.Title className={css(appStyles.title)}>Standard or Extended?&nbsp;</Card.Title>
    <div className="d-flex">
      {displayableServiceLevels.map((level, index) => {
        const { price, displayPrice } = getPriceForSolutionType(
          level,
          orderOptions?.speakerType?.name ?? ''
        );

        return (
          <ServiceLevelCard
            key={level.name}
            index={index + AD_OPTIONS_START_INDEX}
            name={level.name}
            price={price}
            displayPrice={displayPrice}
            description={level.description}
            deliveryDate={level.deliveryDate}
            setServiceLevel={setServiceLevel}
            orderOptions={orderOptions}
          />
        );
      })}
    </div>
  </>
);

export default ServiceLevelSection;
