import {
  CHOOSE_FOR_ME_VENDOR_ID,
  ENGLISH_ID,
  ENGLISH_UK_ID,
  SPANISH_ID,
  SPANISH_SPAIN_ID,
  TRANSPERFECT_VENDOR_ID,
} from '~/helpers/constants';

export const hasAiDubbing = (selectedServices) => {
  return selectedServices.some((service) => {
    return (
      service.serviceType === 'Dubbing' &&
      service.orderOptions?.selectedDubbingOptions.some((option) => option.dubbingType === 'ai')
    );
  });
};

export const hasAiEnabledAudioDescription = (selectedServices) => {
  return selectedServices.some(
    (service) =>
      service.serviceType === 'AudioDescription' &&
      (service.displayName === 'AI Enabled Audio Description' ||
        service.orderOptions?.speakerType?.name === 'AI Only')
  );
};

export const hasBroadcastScripting = (selectedServices) => {
  return selectedServices.some((service) => service.serviceType === 'BroadcastScript');
};

export const hasCaptionStyling = (selectedServices) => {
  return selectedServices.some(
    (service) =>
      service.serviceType === 'Transcription' &&
      (service.orderOptions?.captionStyling?.name === 'Expert' ||
        service.orderOptions?.captionStyling?.name === 'Customized')
  );
};

export const hasChooseForMeAudioDescription = (selectedServices) => {
  const audioDescription = selectedServices.find((s) => s.serviceType === 'AudioDescription');
  return audioDescription?.orderOptions.serviceLevel.name === 'Choose for Me';
};

export const hasDubbing = (selectedServices) => {
  return selectedServices.some((service) => service.serviceType === 'Dubbing');
};

export const hasMediaLocalization = (selectedServices) => {
  return selectedServices.some(
    (service) =>
      service.serviceType === 'Translation' &&
      service.orderOptions?.selectedOptions?.some(
        (opt) => opt?.vendor?.id === CHOOSE_FOR_ME_VENDOR_ID.toString()
      )
  );
};

export function vendorTranscription(service, additionalJumpstartLanguageIds) {
  return isVendorTranscriptionLanguage(
    service.orderOptions.language.ids,
    additionalJumpstartLanguageIds
  );
}

// Determines whether there is a service with a minimum duration and the minimum duration is one that we as 3Play control.
// This is in contrast to a minimum duration set by, for example, TransPerfect.
export const hasServiceWith3playMinimunDuration = (
  selectedServices,
  defaultTranslationVendorId,
  additionalJumpstartLanguageIds
) => {
  // true if there's no VT or Translation
  if (
    !selectedServices.some(
      (service) =>
        service.serviceType === 'Translation' ||
        (service.serviceType === 'Transcription' &&
          vendorTranscription(service, additionalJumpstartLanguageIds))
    )
  ) {
    return true;
  }
  // true if any translation vendors aren't TransPerfect
  const translations = selectedServices.filter((service) => service.serviceType === 'Translation');
  if (
    translations.some((translation) =>
      translation.orderOptions.selectedOptions.some(
        (option) => option.vendor.id != TRANSPERFECT_VENDOR_ID
      )
    )
  ) {
    return true;
  }
  // true if transcription is not TP
  const transcriptions = selectedServices.filter(
    (service) => service.serviceType === 'Transcription'
  );
  return !transcriptions.some((service) =>
    isTransperfectVendorTranscription(
      service,
      defaultTranslationVendorId,
      additionalJumpstartLanguageIds
    )
  );
};

export const hasTranslation = (selectedServices) => {
  return selectedServices.some((service) => service.serviceType === 'Translation');
};

export const hasTransperfectTranscription = (
  selectedServices,
  defaultTranslationVendorId,
  additionalJumpstartLanguageIds
) => {
  let vtOrder = false;
  const transcriptionOrder = selectedServices.filter(
    (service) => service.serviceType === 'Transcription'
  );
  if (transcriptionOrder && transcriptionOrder.length > 0) {
    const languageIds = transcriptionOrder[0].orderOptions?.language?.ids;
    if (languageIds && languageIds.length > 0) {
      vtOrder = isVendorTranscriptionLanguage(languageIds, additionalJumpstartLanguageIds);
    }
    return vtOrder && Number(defaultTranslationVendorId) === TRANSPERFECT_VENDOR_ID;
  }
};

export const hasTransperfectTranslation = (service) => {
  return service[0].orderOptions.selectedOptions.some(
    (too) => Number(too.vendor.id) === TRANSPERFECT_VENDOR_ID
  );
};

export const hasVoiceArtistAudioDescription = (selectedServices) => {
  return selectedServices.some(
    (service) =>
      service.serviceType === 'AudioDescription' &&
      service.orderOptions?.speakerType?.name === 'Voice Artist'
  );
};

export const isTransperfectVendorTranscription = (
  service,
  defaultTranslationVendorId,
  additionalJumpstartLanguageIds
) => {
  return (
    vendorTranscription(service, additionalJumpstartLanguageIds) &&
    Number(defaultTranslationVendorId) === TRANSPERFECT_VENDOR_ID
  );
};

export const isVendorTranscriptionLanguage = (languageIds, additionalJumpstartLanguageIds) => {
  const internalLanguages = [
    ENGLISH_ID,
    SPANISH_ID,
    ENGLISH_UK_ID,
    SPANISH_SPAIN_ID,
    ...additionalJumpstartLanguageIds,
  ];

  return !languageIds.some((id) => internalLanguages.includes(id));
};
