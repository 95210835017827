import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import OrderMore from '~/components/app/order_more/OrderMore';
import OutputAssetShape from '~/components/app/order_more/components/OutputAssetShape';

import ServiceLevelSection from '~/components/app/order_form/serviceOptions/audioDescription/ServiceLevelSection';
import SolutionTypeSection from '~/components/app/order_form/serviceOptions/audioDescription/SolutionTypeSection';
import TurnaroundTimeSection from '~/components/app/order_form/serviceOptions/audioDescription/TurnaroundTimeSection';

import {
  ServiceLevel,
  SolutionType,
  TurnaroundLevel,
} from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';

import {
  OrderOptionsProvider,
  useOrderOptions,
  orderOptionsActions,
} from './audio_descriptions/OrderOptionsContext';

import TipPanelContent from './audio_descriptions/TipPanelContent';

import { getDisplayableServiceLevels } from '~/components/app/order_form/serviceOptions/audioDescription/ServiceLevelSection';
import { getDisplayableTurnaroundLevels } from '~/components/app/order_form/serviceOptions/audioDescription/TurnaroundTimeSection';
import MediaFileSummary, { MediaFile } from './components/MediaFileSummary';
import { useSolutionTypeChange } from './audio_descriptions/useSolutionTypeChange';
import OutputAssetSelector from './components/OutputAssetSelector';
import { AudioMixCode, OutputAsset, MixingOptions } from './components/OutputAssetSelector';
import OutputAssetSummary from '~/components/app/order_more/components/OutputAssetSummary';

interface EmbedSelection {
  code: AudioMixCode;
  name: string;
}

interface AudioMixOption {
  code: AudioMixCode;
  name: string;
  price: number;
  validForExtended: boolean;
  embedSelections: EmbedSelection[];
}

export interface OutputAssetOptions {
  [key: string]: AudioMixOption;
}

interface AudioDescriptionOrderMoreProps {
  authToken: string;
  canOrderMixing: boolean;
  mediaFiles: MediaFile[];
  mixingOptions: MixingOptions;
  serviceLevels: ServiceLevel[];
  solutionTypes: SolutionType[];
  turnaroundLevels: TurnaroundLevel[];
  submissionUrl: string;
}

interface SubmitParams {
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const AudioDescriptionOrderMoreContent = ({
  authToken,
  canOrderMixing,
  mediaFiles,
  mixingOptions,
  serviceLevels,
  turnaroundLevels,
  solutionTypes,
  submissionUrl,
}: AudioDescriptionOrderMoreProps): JSX.Element => {
  const { orderOptions, dispatch } = useOrderOptions();
  const changeSolutionType = useSolutionTypeChange({
    serviceLevels,
    turnaroundLevels,
    solutionTypes,
  });

  const canSubmit = () => {
    if (!canOrderMixing) {
      return true;
    }
    return (
      orderOptions.outputAssets.length > 0 &&
      orderOptions.outputAssets.every((mix) => mix.embeds && mix.embeds.length > 0)
    );
  };

  const onSubmit = ({ setErrors, setSuccess }: SubmitParams): Promise<void> => {
    const outputAssets = orderOptions.outputAssets.map((asset) => {
      return {
        code: asset.code,
        embeds: asset.embeds?.map((e) => e.code),
      };
    });

    const formData = new FormData();
    formData.append('media_file_ids', mediaFiles.map((file) => file.id).join(','));
    formData.append('orderOptions', JSON.stringify(orderOptions));
    formData.append('output_assets', JSON.stringify(outputAssets));

    return new Promise<void>((resolve, reject) => {
      fetch(submissionUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-TOKEN': authToken,
        },
      })
        .then((response) => response.json())
        .then((data: { success: boolean }) => {
          if (data.success) {
            setSuccess(true);
            resolve();
          } else {
            setErrors((currErrors) => [
              ...currErrors,
              'There was an error submitting your order. Please try again.',
            ]);
            reject();
          }
        })
        .catch(reject);
    });
  };

  const summaryContent = null;

  const ADConfirmation = (
    <>
      <h5>Confirm your order</h5>
      <ul>
        <li>
          All audio descriptions shorter than 60 seconds will be charged the minimum of 60 seconds.
        </li>
        <li>
          If you have selected the Choose for Me option, the cost above reflects the maximum total
          if all files require Extended description.
        </li>
        <li>Make sure that your order is correct. All orders are final.</li>
        <li>
          Your files will begin processing immediately. Most requests are completed within 5
          business days. Upon completion, you will receive an email with a link to download your
          audio description files.
        </li>
        <li>
          You can download your audio description as a text WebVTT track or as a plain text, doc, or
          merged text file. You can also download MP4, MP3, or WAV formats merged with synthesized
          speech of your description. All download formats are included in the cost of your order.
        </li>
      </ul>
    </>
  );

  const tipPanelContent = (
    <>
      <MediaFileSummary
        files={mediaFiles}
        authenticityToken={authToken}
        serviceDetails={orderOptions}
      />
      <TipPanelContent />
      {canOrderMixing && (
        <OutputAssetSummary selectedOutputAssets={orderOptions.outputAssets || []} />
      )}
    </>
  );
  const displayableServiceLevels = useMemo(() => {
    const isAiOnly = orderOptions?.speakerType?.name === 'AI Only';
    return getDisplayableServiceLevels(serviceLevels, isAiOnly);
  }, [serviceLevels, orderOptions]);

  const displayableTurnaroundLevels = useMemo(() => {
    const isAiOnly = orderOptions?.speakerType?.name === 'AI Only';
    const isVoiceArtistAD = orderOptions?.speakerType?.name === 'Voice Artist';

    return getDisplayableTurnaroundLevels(turnaroundLevels, isVoiceArtistAD, isAiOnly);
  }, [turnaroundLevels, orderOptions]);

  const setServiceLevel = (serviceLevelName: string) => {
    dispatch(orderOptionsActions.setServiceLevel(serviceLevels, serviceLevelName));
  };

  const setTurnaroundLevel = (turnaroundLevelId: string) => {
    dispatch(orderOptionsActions.setTurnaroundLevel(turnaroundLevels, turnaroundLevelId));
  };

  const handleOutputAssetChange = (assets: OutputAsset[]) => {
    dispatch(orderOptionsActions.setOutputAssets(assets));
  };

  return (
    <OrderMore
      mainContent={
        <>
          <div className="mb-2">
            <Card.Title>Order Audio Description</Card.Title>
            <SolutionTypeSection
              availableSolutionTypes={solutionTypes}
              orderOptions={orderOptions}
              setSolutionType={changeSolutionType}
            />
            <ServiceLevelSection
              displayableServiceLevels={displayableServiceLevels}
              orderOptions={orderOptions}
              setServiceLevel={setServiceLevel}
            />
            <TurnaroundTimeSection
              displayableTurnaroundLevels={displayableTurnaroundLevels}
              orderOptions={orderOptions}
              setTurnaroundLevel={setTurnaroundLevel}
            />
          </div>
          {canOrderMixing && (
            <OutputAssetSelector
              mixingOptions={mixingOptions}
              selectedOutputAssets={orderOptions.outputAssets || []}
              setSelectedOutputAssets={handleOutputAssetChange}
            />
          )}
          {orderOptions.speakerType?.name != 'Voice Artist' &&
            orderOptions.speakerType?.name != 'AI Only' &&
            ADConfirmation}
        </>
      }
      onSubmit={onSubmit}
      orderType="Audio Description"
      submissionDisabled={!canSubmit()}
      summaryContent={summaryContent}
      tipPanelContent={tipPanelContent}
    />
  );
};

const AudioDescriptionOrderMore = (props: AudioDescriptionOrderMoreProps): JSX.Element => {
  return (
    <OrderOptionsProvider
      initialOptions={{
        solutionTypes: props.solutionTypes,
        serviceLevels: props.serviceLevels,
        turnaroundLevels: props.turnaroundLevels,
      }}
    >
      <AudioDescriptionOrderMoreContent {...props} />
    </OrderOptionsProvider>
  );
};

const serviceLevelShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  voiceArtistDisplayPrice: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

const turnaroundLevelShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  displayName: PropTypes.string,
  deadline: PropTypes.string,
  surcharge: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.string,
  }),
});

AudioDescriptionOrderMore.propTypes = {
  authToken: PropTypes.string.isRequired,
  canOrderMixing: PropTypes.bool.isRequired,
  mediaFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  mixingOptions: PropTypes.shape({
    embed: PropTypes.arrayOf(OutputAssetShape).isRequired,
    mix: PropTypes.arrayOf(OutputAssetShape).isRequired,
  }).isRequired,
  serviceLevels: PropTypes.arrayOf(serviceLevelShape).isRequired,
  submissionUrl: PropTypes.string.isRequired,
  turnaroundLevels: PropTypes.arrayOf(turnaroundLevelShape).isRequired,
};

export default AudioDescriptionOrderMore;
