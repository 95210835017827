import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import MultiServiceTable from './MultiServiceTable';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

function SubtitlingServiceCard(props) {
  const headers = ['Language', 'Turnaround', 'Ordered'];

  return (
    <MultiServiceTable headers={headers}>
      {props.services.map((service) => (
        <tr key={service.id}>
          <td>{service.targetLanguage.fullName}</td>
          <td>{service.turnaroundLevel}</td>
          <td>
            {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </td>
        </tr>
      ))}
    </MultiServiceTable>
  );
}

SubtitlingServiceCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  userTimeZone: PropTypes.string,
};

export default SubtitlingServiceCard;
