/* NOTE: This was developed for the AudioDescriptionOrderMore component and has some
hardcoded values. It may need to be refactored to be more generic and reusable.
*/

import { useState, useEffect, useRef } from 'react';
import { MediaFile } from '../components/MediaFileSummary';
import { OrderOptions } from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';
import { SolutionType } from '~/components/app/order_form/serviceOptions/audioDescription/SolutionTypeSection';
interface PriceInfo {
  price: number | null;
  pricerate?: number;
}

interface MediaPriceResult {
  filePrices: Map<number, PriceInfo>;
  totalPrice: number | null;
}
interface PriceResponse {
  price: number | null;
  pricerate?: number;
  project?: number;
}

type ServiceDetails = Array<{
  serviceType: string;
  languages: number[];
  serviceLevel: string;
  turnaroundLevel: number;
  orderOption: { speakerType?: Omit<SolutionType, 'description'> };
}>;

const getServiceDetails = (payload: OrderOptions): string => {
  return payload?.speakerType?.name === 'Voice Artist'
    ? 'AudioDescriptions::VoiceOvers::VoiceOverAudioDescription'
    : 'AudioDescription';
};

const getPrice = async (
  token: string,
  durationInSeconds: number,
  payload: OrderOptions,
  languageId: number
): Promise<PriceInfo> => {
  const data = new FormData();
  data.append('authenticity_token', token);
  data.append('duration', durationInSeconds.toString());

  const apiPayload: ServiceDetails = [
    {
      serviceType: getServiceDetails(payload),
      languages: [languageId],
      serviceLevel: payload.serviceLevel?.name || '',
      turnaroundLevel: payload.turnaroundLevel?.id ? Number(payload.turnaroundLevel.id) : 0,
      orderOption: payload.speakerType ? { speakerType: payload.speakerType } : {},
    },
  ];

  data.append('serviceDetails', JSON.stringify(apiPayload));

  const priceData = await fetch('/service_pricing/compute', {
    method: 'POST',
    body: data,
  }).then((response) => response.json() as Promise<PriceResponse>);
  return {
    price: priceData.price,
    pricerate: priceData.pricerate,
  };
};

export function useMediaPrices(
  files: MediaFile[],
  authenticityToken?: string,
  serviceDetails?: OrderOptions
): MediaPriceResult {
  const [filePrices, setFilePrices] = useState<Map<number, PriceInfo>>(new Map());
  const [totalPrice, setTotalPrice] = useState<number | null>(null);
  const isCalculatingRef = useRef(false);

  useEffect(() => {
    const calculatePrices = async () => {
      if (!files || files.length === 0 || !authenticityToken || !serviceDetails) return;
      if (isCalculatingRef.current) return; // Prevent concurrent calculations

      isCalculatingRef.current = true;
      try {
        const pricePromises = files.map((file) =>
          getPrice(authenticityToken, file.duration / 1000, serviceDetails, file.languageId).then(
            (priceInfo) => ({
              fileId: file.id,
              priceInfo,
            })
          )
        );

        const results = await Promise.all(pricePromises);

        const newPrices = new Map<number, PriceInfo>();
        let totalPriceValue = 0;

        results.forEach(({ fileId, priceInfo }) => {
          newPrices.set(fileId, priceInfo);
          if (priceInfo.price !== null) {
            totalPriceValue += priceInfo.price;
          }
        });

        setFilePrices(newPrices);
        setTotalPrice(totalPriceValue);
      } catch (error) {
        console.error('Error calculating prices:', error);
      } finally {
        isCalculatingRef.current = false;
      }
    };

    void calculatePrices();
  }, [files, authenticityToken, serviceDetails]);

  return { filePrices, totalPrice };
}
