import PropTypes from 'prop-types';

const aiDubbingOrderOptionType = PropTypes.shape({
  id: PropTypes.number,
  sourceLanguage: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.number,
  }),
  targetLanguage: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.number,
  }),
  price: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.string,
  }),
  translationType: PropTypes.string,
});

const embedOptionsType = PropTypes.arrayOf(
  PropTypes.shape({
    code: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
  })
);

const liveUpgradeDetailsType = PropTypes.shape({
  liveFileId: PropTypes.string,
  liveFileName: PropTypes.string,
});

const mixOptionsType = PropTypes.arrayOf(
  PropTypes.shape({
    code: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
    price: PropTypes.number,
  })
);

const mixingOrderOptionsType = PropTypes.shape({
  mix: mixOptionsType,
  embed: embedOptionsType,
});

const subtitlingOrderOptionsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    price: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
    }).isRequired,
    sourceLanguage: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
    targetLanguage: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
    turnaroundLevel: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    translationProfileID: PropTypes.number,
  })
);

const selectedServicesType = PropTypes.arrayOf(
  PropTypes.shape({
    description: PropTypes.string,
    displayName: PropTypes.string,
    price: PropTypes.shape({
      amount: PropTypes.number,
      unit: PropTypes.string,
    }),
    serviceType: PropTypes.string,
    tooltip: PropTypes.string,
  })
);

const translationOrderOptionsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.shape({
      amount: PropTypes.number,
      unit: PropTypes.string,
    }),
    serviceLevel: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    serviceLevelType: PropTypes.shape({
      id: PropTypes.number,
      codeName: PropTypes.string,
      displayName: PropTypes.string,
    }),
    sourceLanguage: PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.number,
    }),
    targetLanguage: PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.number,
    }),
    translationVendor: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  })
);

const translationProfileType = PropTypes.shape({
  id: PropTypes.string,
  default: PropTypes.bool,
  name: PropTypes.string,
});

export {
  aiDubbingOrderOptionType,
  embedOptionsType,
  liveUpgradeDetailsType,
  mixingOrderOptionsType,
  selectedServicesType,
  subtitlingOrderOptionsType,
  translationOrderOptionsType,
  translationProfileType,
};
