import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import TranslationOrderOptionsTable from '~/components/app/order_form/serviceOptions/translation/TranslationOrderOptionsTable';
import TranslationTypeSelector from '~/components/app/order_form/serviceOptions/translation/TranslationTypeSelector';

import OrderMore from '~/components/app/order_more/OrderMore';
import {
  translationOrderOptionsType,
  translationProfileType,
} from '~/components/app/order_form/propTypes';

const TranslationOrderSummary = ({ selectedFormats }) => {
  return (
    <div>
      <b>Translation Ordered</b>
      <ul>
        {selectedFormats.map((format) => (
          <li key={format.id}>{format.targetLanguage}</li>
        ))}
      </ul>
    </div>
  );
};

TranslationOrderSummary.propTypes = {
  selectedFormats: PropTypes.arrayOf(PropTypes.any),
};

const TranslationTipPanel = () => {
  return (
    <>
      <p>
        <strong>General/Accessibility (SDH subtitles):</strong> Choose this if you need translation
        or SDH subtitles for accessibility accommodations. This translation is also best for a
        general corporate (legal, finance, tech, marketing) and educational content. If your content
        has medical or life sciences terminology, please choose Medical/Life Sciences.
      </p>

      <p>
        <strong>Media/Entertainment (non-SDH subtitles):</strong> Choose this if you need non-SDH
        subtitles. Non-SDH subtitles are intended for a hearing audience in another language. They
        are the standard subtitle type for the distribution of content internationally.
      </p>
    </>
  );
};

const TranslationOrderMore = ({
  authToken,
  managedAccount,
  mediaFiles,
  sourceLanguage,
  submissionUrl,
  translationOrderOptions,
  translationProfiles,
  defaultTranslationVendorId
}) => {
  // Mimic order form state, so that we can start reusing components.
  const [availableLanguagesForType, setAvailableLanguagesForType] = useState([]);
  const [availableOptionsForLanguages, setAvailableOptionsForLanguages] = useState({});
  const [availableServiceLevelTypes, setAvailableServiceLevelTypes] = useState([]);
  const [refusedSourceLanguageTemplate, setRefusedSourceLanguageTemplate] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedOrderOptions, setSelectedOrderOptions] = useState([]);
  const [selectedServiceLevelType, setSelectedServiceLevelType] = useState('');

  // Create a unique list of service level types from the provided order options
  useEffect(() => {
    if (translationOrderOptions) {
      const typesMap = translationOrderOptions.reduce((acc, option) => {
        const { id, codeName, displayName } = option.serviceLevelType;
        if (!acc[id]) {
          acc[id] = { id, codeName, displayName };
        }
        return acc;
      }, {});

      const types = Object.values(typesMap);
      setAvailableServiceLevelTypes(types);

      // Set default selection to first type if we have any types
      if (types.length > 0 && !selectedServiceLevelType) {
        setSelectedServiceLevelType(types[0].displayName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationOrderOptions]);

  // Filter the available languages based on the selected service level type
  useEffect(() => {
    if (selectedServiceLevelType) {
      const filteredOptions = translationOrderOptions.filter(
        (option) => option.serviceLevelType.displayName === selectedServiceLevelType
      );

      // Get unique languages from filtered options
      const languagesMap = filteredOptions.reduce((acc, option) => {
        const { id, fullName } = option.targetLanguage;
        if (!acc[id]) {
          acc[id] = { id, fullName };
        }
        return acc;
      }, {});

      setAvailableLanguagesForType(
        Object.values(languagesMap).sort((a, b) => a.fullName.localeCompare(b.fullName))
      );
    } else {
      setAvailableLanguagesForType([]);
    }

    setSelectedLanguages([]);
  }, [selectedServiceLevelType, translationOrderOptions]);

  // Update available language options when selected languages change
  useEffect(() => {
    const optionsForLanguages = {};
    const initialSelections = [];

    selectedLanguages.forEach((language) => {
      const languageOptions = translationOrderOptions.filter(
        (option) =>
          option.serviceLevelType.displayName === selectedServiceLevelType &&
          option.targetLanguage.fullName === language.fullName
      );

      optionsForLanguages[language.fullName] = languageOptions;

      // Check to see if this language was already previously selected
      const existingOption = selectedOrderOptions.find(
        option => option.targetLanguage === language.fullName
      );

      // Make sure to keep the previously selected order options
      if (existingOption) {
        initialSelections.push(existingOption)
      }
      else if (!existingOption && languageOptions.length > 0) {
        // If there is a default vendor, try to find option with that vendor
        let selectedOption = defaultTranslationVendorId ? languageOptions.find(opt => opt.translationVendor.id === defaultTranslationVendorId) : null;

        // fall back to first option if no matching vendor option found
        selectedOption = selectedOption || languageOptions[0]

        initialSelections.push({
          id: selectedOption.id,
          targetLanguage: language.fullName,
          vendor: selectedOption.translationVendor,
          serviceLevel: selectedOption.serviceLevel,
          translationProfile: translationProfiles[0]?.id,
          price: selectedOption.price,
          optionType: selectedOption.optionType,
        });
      }
    });

    setAvailableOptionsForLanguages(optionsForLanguages);
    setSelectedOrderOptions(initialSelections);

  }, [selectedLanguages, selectedServiceLevelType, translationOrderOptions, translationProfiles]);

  const onSubmit = ({ setErrors, setSuccess }) => {
    const formData = new FormData();
    formData.append('media_file_ids', mediaFiles.map((file) => file.id).join(','));
    formData.append(
      'selected_translation_options',
      selectedOrderOptions
        .map((option) =>
          JSON.stringify({
            order_option_id: option.id,
            option_type: option.optionType,
            translation_profile_id: option.translationProfile,
          })
        )
        .join('|')
    );
    formData.append('include_source_language_template', !refusedSourceLanguageTemplate);

    return fetch(submissionUrl, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-TOKEN': authToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess(true);
        } else {
          setErrors((currErrors) => [
            ...currErrors,
            'There was an error submitting your order. Please try again.',
          ]);
        }
      });
  };

  const setSelectedServiceForLanguage = (language, serviceLevelId) => {
    setSelectedOrderOptions((current) => {
      return current.map((option) => {
        if (option.targetLanguage === language) {
          const serviceOption = availableOptionsForLanguages[language].find(
            (opt) => opt.serviceLevel.id.toString() === serviceLevelId
          );

          if (!serviceOption) {
            console.error('No service option found for', serviceLevelId);
            return option;
          }

          return {
            ...option,
            id: serviceOption.id,
            serviceLevel: serviceOption.serviceLevel,
            price: serviceOption.price,
          };
        }
        return option;
      });
    });
  };

  const setSelectedTranslationProfile = (language, profileId) => {
    setSelectedOrderOptions((current) => {
      return current.map((option) => {
        if (option.targetLanguage === language) {
          return {
            ...option,
            translationProfile: profileId,
          };
        }
        return option;
      });
    });
  };

  const setSelectedVendorForLanguage = (language, vendorId) => {
    setSelectedOrderOptions((current) => {
      return current.map((option) => {
        if (option.targetLanguage === language) {
          // Find first available option for this vendor
          const vendorOption = availableOptionsForLanguages[language].find(
            (opt) => opt.translationVendor.id.toString() === vendorId
          );

          if (!vendorOption) {
            console.error('No vendor option found for', vendorId);
            return option;
          }

          return {
            ...option,
            id: vendorOption.id,
            vendor: vendorOption.translationVendor,
            serviceLevel: vendorOption.serviceLevel,
            price: vendorOption.price,
          };
        }
        return option;
      });
    });
  };

  return (
    <OrderMore
      mainContent={
        <>
          <TranslationTypeSelector
            translationTypes={availableServiceLevelTypes}
            selectedTranslationType={selectedServiceLevelType}
            setTranslationType={setSelectedServiceLevelType}
          />
          {selectedServiceLevelType && (
            <>
              <Card.Title className={css(appStyles.title)}>Select Languages</Card.Title>
              <Card.Subtitle className="text-muted mb-2">
                Select languages that you want to translate this content into:
              </Card.Subtitle>
              <Typeahead
                clearButton
                id="transcription-language-dropdown"
                labelKey="fullName"
                multiple={true}
                onChange={setSelectedLanguages}
                options={availableLanguagesForType}
                placeholder="Search Languages"
                selected={selectedLanguages}
              />
              <TranslationOrderOptionsTable
                availableOptionsForLanguages={availableOptionsForLanguages}
                selectedOrderOptions={selectedOrderOptions}
                sourceLanguage={sourceLanguage.fullName}
                setSelectedServiceForLanguage={setSelectedServiceForLanguage}
                setSelectedTranslationProfile={setSelectedTranslationProfile}
                setSelectedVendorForLanguage={setSelectedVendorForLanguage}
                translationOrderOptions={translationOrderOptions}
                translationProfiles={translationProfiles}
              />
              {managedAccount && selectedLanguages.length > 0 && (
                <Form.Group>
                  <Form.Check
                    checked={refusedSourceLanguageTemplate}
                    className="d-inline-block"
                    onChange={() => setRefusedSourceLanguageTemplate((current) => !current)}
                  />
                  <Form.Label>I do not need a source language template</Form.Label>
                </Form.Group>
              )}
            </>
          )}
        </>
      }
      onSubmit={onSubmit}
      orderType="Translation"
      submissionDisabled={selectedOrderOptions.length === 0}
      summaryContent={<TranslationOrderSummary selectedFormats={selectedOrderOptions} />}
      tipPanelContent={<TranslationTipPanel />}
    />
  );
};

TranslationOrderMore.propTypes = {
  authToken: PropTypes.string,
  managedAccount: PropTypes.bool,
  mediaFiles: PropTypes.arrayOf(PropTypes.any),
  sourceLanguage: PropTypes.shape({
    id: PropTypes.number,
    fullName: PropTypes.string,
  }),
  submissionUrl: PropTypes.string,
  translationOrderOptions: translationOrderOptionsType,
  translationProfiles: PropTypes.arrayOf(translationProfileType),
  defaultTranslationVendorId: PropTypes.number
};

export default TranslationOrderMore;
