import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import { Button } from 'react-bootstrap';

import WithIcon from '~/components/app/common/WithIcon';
import { transcriptListSpec, transcriptSpec } from './data/propspec';
import TranscriptLanguage from './transcript_actions/TranscriptLanguage';
import QuickLinkButton from './QuickLinkButton';

function TranscriptActions(props) {
  const flagClass =
    'has_tooltip ' + css(styles.flagButton, props.showFlags && styles.activeFlagButton);

  return (
    <div className={css(styles.actionBar)}>
      {props.transcript && props.fileId && props.transcript.wordCount > 0 && (
        <React.Fragment>
          {props.enabledFeatures.includes('other') && (
            <Button
              variant="outline-secondary"
              size="sm"
              className={css(styles.previewButton)}
              onClick={() => props.setShowModal.preview(true)}
            >
              <WithIcon icon="fa fa-cc">CC Preview</WithIcon>
            </Button>
          )}

          <Button
            variant="outline-warning"
            size="sm"
            className={flagClass}
            onClick={() => props.toggleFlags()}
          >
            <WithIcon icon="fa fa-flag">Flag</WithIcon>
          </Button>

          {props.enabledFeatures.includes('other') && props.contentPublishing && (
            <QuickLinkButton
              transcript={props.transcript}
              fileId={props.fileId}
              transcriptList={props.transcriptList}
              setShowModal={props.setShowModal}
              projectId={props.projectId}
            />
          )}
        </React.Fragment>
      )}
      {props.transcriptList && (
        <TranscriptLanguage
          transcript={props.transcript}
          transcriptList={props.transcriptList}
          updateTranscript={props.updateTranscript}
        />
      )}
    </div>
  );
}

TranscriptActions.propTypes = {
  contentPublishing: PropTypes.bool,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  fileId: PropTypes.string,
  projectId: PropTypes.string,
  setShowModal: PropTypes.shape({
    preview: PropTypes.func,
  }),
  showFlags: PropTypes.bool,
  toggleFlags: PropTypes.func,
  transcriptList: transcriptListSpec,
  transcript: transcriptSpec,
  updateTranscript: PropTypes.func,
};

const styles = StyleSheet.create({
  actionBar: {
    padding: '4px 13px',
    borderBottom: '2px solid #D8D8D8',
    height: '41px',
  },
  previewButton: {
    color: '#4D4D4D',
    ':hover': {
      color: '#FFFFFF',
    },
    height: '31px',
  },
  flagButton: {
    'margin-left': '0.3em',
    height: '31px',
    color: '#4D4D4D',
    ':hover': {
      backgroundColor: '#FFDD99',
    },
  },
  activeFlagButton: {
    backgroundColor: '#FFCC66',
    ':hover': {
      backgroundColor: '#FFBB33',
    },
  },
});

export default TranscriptActions;
